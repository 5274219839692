import { ApplicationConfig, inject, LOCALE_ID } from "@angular/core";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { httptokenInterceptor } from "./access/services/httptoken.interceptor";
import { httpi18nInterceptor } from "./common/i18n/httpi18n.interceptor";
import { CURRENT_LOCALE_ID } from "./i18n_setup";
import { http403ResponseInterceptor } from "./access/services/http403response.interceptor";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEn from "@angular/common/locales/en";
import {
  NavigationError,
  NoPreloading,
  provideRouter,
  Router,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withNavigationErrorHandler,
  withPreloading,
  withRouterConfig,
} from "@angular/router";
import { APP_ROUTES } from "./app-routes";
import { I18N_ROUTE_SEGMENTS } from "./route_segments";
import {
  provideClientHydration,
  withNoDomReuse,
  withNoHttpTransferCache,
} from "@angular/platform-browser";

registerLocaleData(localeFr);
registerLocaleData(localeEn);

export const appCommonConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([
        httptokenInterceptor,
        httpi18nInterceptor,
        http403ResponseInterceptor,
      ])
    ),
    { provide: LOCALE_ID, useValue: CURRENT_LOCALE_ID },
    provideRouter(
      APP_ROUTES,
      withPreloading(NoPreloading),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
      withNavigationErrorHandler((e: NavigationError) => {
        inject(Router).navigate([I18N_ROUTE_SEGMENTS.error + "/404"], {
          skipLocationChange: true,
        });
      }),
      withRouterConfig({
        onSameUrlNavigation: "reload",
        paramsInheritanceStrategy: "always",
      })
    ),
    provideClientHydration(withNoDomReuse(), withNoHttpTransferCache()),
  ],
};
