import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class HeadService {
  constructor(@Inject(DOCUMENT) public document: Document) {}

  public setCanonical(
    href: string,
    next?: string | null,
    prev?: string | null
  ): void {
    const link: HTMLLinkElement =
      this.document.head.querySelector("link[rel='canonical']") ||
      this.document.head.appendChild(this.document.createElement("link"));
    link.rel = "canonical";
    link.href = href;

    if (next) {
      const nextLink: HTMLLinkElement =
        this.document.head.querySelector("link[rel='next']") ||
        this.document.head.appendChild(this.document.createElement("link"));
      nextLink.rel = "next";
      nextLink.href = next;
    } else {
      const nextLink = this.document.querySelector("link[rel='next']");
      if (nextLink && nextLink.parentNode) {
        nextLink.parentNode.removeChild(nextLink);
      }
    }

    if (prev) {
      const prevLink: HTMLLinkElement =
        this.document.head.querySelector("link[rel='prev']") ||
        this.document.head.appendChild(this.document.createElement("link"));
      prevLink.rel = "prev";
      prevLink.href = prev;
    } else {
      const prevLink = this.document.querySelector("link[rel='prev']");
      if (prevLink && prevLink.parentNode) {
        prevLink.parentNode.removeChild(prevLink);
      }
    }
  }

  public setAlternate(lang: string, href: string): void {
    const link: HTMLLinkElement =
      this.document.head.querySelector(
        `link[rel='alternate'][hreflang=${lang}]`
      ) || this.document.head.appendChild(this.document.createElement("link"));
    link.rel = "alternate";
    link.hreflang = lang;
    link.href = href;
  }

  public setHtmlLanguage(lang: string): void {
    const html: HTMLElement | null = this.document.querySelector("html");
    if (html) {
      html.lang = lang;
    }
  }
}
