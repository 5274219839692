import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CookiesService } from "../cookies/cookies.service";
import { StorageService } from "../storage/storage.service";
import { isPlatformBrowser } from "@angular/common";
import { checkRandomString, getCheckableRandomString } from "../strings/random";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DeviceIdService {
  private _deviceId?: string;

  constructor(
    private _cook: CookiesService,
    private _stor: StorageService,
    @Inject(PLATFORM_ID) private platform: any
  ) {}

  public get current(): string {
    if (this._deviceId === undefined) {
      if (isPlatformBrowser(this.platform)) {
        // On browser, get from localStorage, and set to cookie
        this._deviceId = this._stor.getItem("device_id");
        if (this._deviceId === null || this._deviceId === undefined) {
          this._deviceId = getCheckableRandomString(20);
        } else {
          if (!checkRandomString(this._deviceId, 20)) {
            this._deviceId = getCheckableRandomString(20);
          }
        }
        this._stor.setItem("device_id", this._deviceId);
        const exp = new Date();
        exp.setDate(exp.getDate() + 3650);
        this._cook.put("device_id", this._deviceId, {
          path: "/",
          expires: exp,
          secure: environment.production,
          httpOnly: false,
          sameSite: "Strict",
        });
      } else {
        // On server, get from cookie or return empty device id
        this._deviceId = this._cook.get("device_id");
        if (this._deviceId === undefined || this._deviceId === null) {
          this._deviceId = "";
        }
      }
    }
    return this._deviceId;
  }

  public clear(): void {
    if (isPlatformBrowser(this.platform)) {
      // On browser, remove from localStorage and cookie
      this._stor.clear("device_id");
      this._cook.remove("device_id");
    } else {
      // On server, remove from cookie
      this._cook.remove("device_id");
    }
  }
}
