import { Inject, Injectable, NgZone, PLATFORM_ID } from "@angular/core";
import { Observable, ReplaySubject, Subscription, timer } from "rxjs";
import { isPlatformBrowser } from "@angular/common";

export enum TYPE {
  SUCCESS,
  INFO,
  WARNING,
  DANGER,
}

export enum DEFAULT_TIMEOUTS {
  SUCCESS = 3000,
  INFO = 4000,
  WARNING = 10000,
  DANGER = 30000,
}

export enum STATUS {
  UNDISP,
  DISP,
  ACK,
}

const DEFAULT_MESSAGE_TIMEOUT = 5000;

export class Message {
  public status: string = STATUS[STATUS.UNDISP];
  public showTrace = false;

  constructor(
    public title: string,
    public type: TYPE = TYPE.INFO,
    public message: string = "",
    public trace: any = null,
    public ttl: number = DEFAULT_MESSAGE_TIMEOUT
  ) {}

  public get style(): string {
    if (this.type === TYPE.DANGER) {
      return "alert alert-dismissible alert-danger";
    }
    if (this.type === TYPE.INFO) {
      return "alert alert-dismissible alert-info";
    }
    if (this.type === TYPE.WARNING) {
      return "alert alert-dismissible alert-warning";
    }
    if (this.type === TYPE.SUCCESS) {
      return "alert alert-dismissible alert-success";
    }
    return "alert alert-dismissible alert-success";
  }

  public ack(): void {
    this.status = STATUS[STATUS.ACK];
  }
}

@Injectable({
  providedIn: "root",
})
export class MessageService {
  public _messages$: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);
  private _messages: Message[] = [];
  private _timer!: Observable<any>;
  private _subscription!: Subscription;
  private _changed = true;

  constructor(
    public ngZone: NgZone,
    @Inject(PLATFORM_ID) private _platform: any
  ) {
    this.init();
  }

  public get current(): Observable<Message[]> {
    return this._messages$.asObservable();
  }

  public updateCurrentMessages(): void {
    const out: Message[] = [];
    for (const message of this._messages) {
      if (
        message.status === STATUS[STATUS.UNDISP] ||
        message.status === STATUS[STATUS.DISP]
      ) {
        message.status = STATUS[STATUS.DISP];
        out.push(message);
      }
    }
    this._messages$.next(out);
    this._changed = false;
  }

  public push(m: Message): void {
    for (const msg of this._messages) {
      if (msg.title === m.title && m.type === msg.type) {
        msg.ttl = m.ttl;
      }
    }
    this._messages.push(m);
  }

  public init(): void {
    if (!isPlatformBrowser(this._platform)) {
      return;
    }
    if (!this._timer) {
      this.ngZone.runOutsideAngular(() => {
        this._timer = timer(500, 500);
        this._subscription = this._timer.subscribe(() => {
          for (const m of this._messages) {
            m.ttl -= 500;
            if (m.ttl <= 0) {
              this._changed = true;
            }
          }
          if (this._changed) {
            this.ngZone.run(() => {
              const out = [];
              for (const m of this._messages) {
                if (m.ttl >= 0) {
                  out.push(m);
                }
              }
              this._messages = out;
              this.updateCurrentMessages();
            });
          }
        });
      });
    }
  }

  public close(): void {
    this._subscription.unsubscribe();
  }

  public success(title: string, message: string = "", trace: any = null): void {
    this.push(
      new Message(title, TYPE.SUCCESS, message, trace, DEFAULT_TIMEOUTS.SUCCESS)
    );
    this.updateCurrentMessages();
  }

  public info(title: string, message: string = "", trace: any = null): void {
    this.push(
      new Message(title, TYPE.INFO, message, trace, DEFAULT_TIMEOUTS.INFO)
    );
    this.updateCurrentMessages();
  }

  public warning(title: string, message: string = "", trace: any = null): void {
    this.push(
      new Message(title, TYPE.WARNING, message, trace, DEFAULT_TIMEOUTS.WARNING)
    );
    this.updateCurrentMessages();
  }

  public danger(title: string, message: string = "", trace: any = null): void {
    this.push(
      new Message(title, TYPE.DANGER, message, trace, DEFAULT_TIMEOUTS.DANGER)
    );
    this.updateCurrentMessages();
  }

  public error(title: string, message: string = "", trace: any = null): void {
    this.danger(title, message, trace);
    console.error(title, message, trace);
  }

  public debug(title: string, message: string = "", trace: any = null): void {
    console.log(title, message, trace);
  }

  public ack(message: Message): void {
    message.ack();
    this.updateCurrentMessages();
  }
}
