import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { I18N_PAGES_SLUGS, I18N_ROUTES } from "../../../app-routes";
import { Rubric } from "../../../articles/services/rubric";
import { RubricService } from "../../../articles/services/rubric.service";
import { DeployUrlService } from "../../../common/display/deploy-url.service";
import { first } from "rxjs/operators";
import { RouterLink } from "@angular/router";
import { NgFor, NgIf } from "@angular/common";

@Component({
  selector: "cobs-footer",
  templateUrl: "./footer.component.pug",
  styleUrls: ["./footer.component.sass"],
  standalone: true,
  imports: [NgFor, NgIf, RouterLink],
})
export class FooterComponent implements OnInit {
  public isStaging =
    environment.MODE === "devel" || environment.MODE === "staging";
  public I18N_ROUTES = I18N_ROUTES;
  public logoUrl!: string;
  public rubrics!: Rubric[][];
  public I18N_PAGES = I18N_PAGES_SLUGS;
  public version = environment.VERSION + " build " + environment.BUILD;

  constructor(
    private _rubrics: RubricService,
    public deploy: DeployUrlService
  ) {}

  public ngOnInit() {
    const rsplit = 2.6;
    this.logoUrl = this.deploy.url("/assets/images/logos/logo_odc_white.svg");
    this._rubrics.all$.pipe(first()).subscribe((res) => {
      this.rubrics = [[]];
      let curLevel = 0;
      let curgroup: Rubric[] = [];
      for (const r of res) {
        if (r.status !== "OK") {
          continue;
        }
        if (r.level === curLevel) {
          curgroup.push(r);
        } else if (r.level < curLevel) {
          if (
            this.rubrics[this.rubrics.length - 1].length + curgroup.length >
            res.length / rsplit
          ) {
            this.rubrics.push(curgroup);
          } else {
            for (const cg of curgroup) {
              this.rubrics[this.rubrics.length - 1].push(cg);
            }
          }
          curgroup = [r];
          curLevel = r.level;
        } else if (r.level > curLevel) {
          curgroup.push(r);
          curLevel = r.level;
        }
      }
      if (
        this.rubrics[this.rubrics.length - 1].length + curgroup.length >
        res.length / rsplit
      ) {
        this.rubrics.push(curgroup);
      } else {
        for (const cg of curgroup) {
          this.rubrics[this.rubrics.length - 1].push(cg);
        }
      }
    });
  }
}
