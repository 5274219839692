import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  constructor(@Inject(PLATFORM_ID) private _platform: any) {
    // Set initial data as empty
    this.data = {};
  }

  private _data = new ReplaySubject<any>(1);

  public set data(values: any) {
    this._data.next(values);
  }

  public get data$(): Observable<any> {
    const out = this._data.asObservable();
    if (!isPlatformBrowser(this._platform)) {
      return out.pipe(first());
    }
    return out;
  }
}
