import { HttpClient } from "@angular/common/http";
import { makeStateKey, TransferState } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformServer } from "@angular/common";
import { Collection } from "./data-collection";
import { DataModel } from "./data-model";

const MAX_TRANSFERSTATE_TIME = 90 * 1000;

@Injectable({
  providedIn: "root",
})
export class DataBackend {
  private _created = new Date();

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platform: any
  ) {}

  public action<T extends DataModel>(
    collection: Collection<T>,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    url: string,
    body: any,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<any> {
    if (method === "GET") {
      const key = makeStateKey(
        url + "|" + JSON.stringify(params) + "|" + JSON.stringify(headers)
      );
      let res: Observable<any>;
      if (
        this.transferState.hasKey(key) &&
        new Date().getTime() - this._created.getTime() < MAX_TRANSFERSTATE_TIME
      ) {
        res = of(this.transferState.get(key, null));
      } else {
        res = this.http.get<any>(url, { headers, params }).pipe(
          tap((result) => {
            if (isPlatformServer(this.platform)) {
              this.transferState.set(key, result);
            }
          })
        );
      }
      return res;
    } else {
      return this.http.request(method, url, { body, params, headers });
    }
  }

  public put<T extends DataModel>(
    collection: Collection<T>,
    url: string,
    body: any,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<T> {
    return this.http.put(url, body, { params, headers }).pipe(
      map((res) => {
        return collection.fromJson(res);
      })
    );
  }

  public post<T extends DataModel>(
    collection: Collection<T>,
    url: string,
    body: any,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<T> {
    return this.http.post(url, body, { params, headers }).pipe(
      map((res) => {
        return collection.fromJson(res);
      })
    );
  }

  public patch<T extends DataModel>(
    collection: Collection<T>,
    url: string,
    body: any,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<T> {
    return this.http.patch(url, body, { params, headers }).pipe(
      map((res) => {
        return collection.fromJson(res);
      })
    );
  }

  public delete<T extends DataModel, R>(
    collection: Collection<T>,
    url: string,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<R> {
    return this.http.delete<R>(url, { params, headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public get<T extends DataModel>(
    collection: Collection<T>,
    url: string,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<T> {
    const key = makeStateKey(
      url + "|" + JSON.stringify(params) + "|" + JSON.stringify(headers)
    );
    let res: Observable<any>;
    if (
      collection.useCache &&
      this.transferState.hasKey(key) &&
      new Date().getTime() - this._created.getTime() < MAX_TRANSFERSTATE_TIME
    ) {
      res = of(this.transferState.get(key, null));
    } else {
      res = this.http.get<any>(url, { headers, params }).pipe(
        tap((result) => {
          if (collection.useCache && isPlatformServer(this.platform)) {
            this.transferState.set(key, result);
          }
        })
      );
    }
    return res.pipe(
      first(),
      map((result) => {
        return collection.fromJson(result);
      })
    );
  }

  public list<T extends DataModel>(
    collection: Collection<T>,
    url: string,
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<any> {
    const key = makeStateKey(
      url + "|" + JSON.stringify(params) + "|" + JSON.stringify(headers)
    );
    let res: Observable<any>;
    if (
      this.transferState.hasKey(key) &&
      new Date().getTime() - this._created.getTime() < MAX_TRANSFERSTATE_TIME
    ) {
      res = of(this.transferState.get(key, null));
    } else {
      res = this.http.get<any>(url, { headers, params }).pipe(
        tap((result) => {
          if (isPlatformServer(this.platform)) {
            this.transferState.set(key, result);
          }
        })
      );
    }
    return res.pipe(
      first(),
      map((result) => {
        return collection.fromJson(result);
      })
    );
  }
}
