import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";

export interface IBooleanFieldMetadata extends IFieldMetadata {}

export class BooleanFieldManager
  extends BaseFieldManager
  implements IBooleanFieldMetadata {}

export function booleanField(updates: IBooleanFieldMetadata = {}) {
  return GenericDataField(updates, "booleanField", BooleanFieldManager);
}
