import { Injectable } from "@angular/core";
import { RubricService } from "../../articles/services/rubric.service";
import { Rubric } from "../../articles/services/rubric";
import { I18N_ROUTES } from "../../app-routes";
import { Observable, ReplaySubject } from "rxjs";

export interface IMenuItem {
  link: string[] | string;
  label: string;
  external: string;
  subItems?: IMenuItem[];
}

@Injectable({
  providedIn: "root",
})
export class MainMenuService {
  private _menu!: ReplaySubject<IMenuItem[]>;
  private _idrs: { [index: number]: Rubric } = {};

  constructor(private _rubrics: RubricService) {}

  public get current$(): Observable<IMenuItem[]> {
    if (!this._menu) {
      this._menu = new ReplaySubject<any>(1);
      this._rubrics.all$.subscribe((rubs) => {
        this._menu.next(this._makeMenu(rubs));
      });
    }
    return this._menu.asObservable();
  }

  private _getRub(id: number): Rubric {
    const nf = new Rubric();
    nf.name = `TODO: ${id}`;
    return this._idrs[id] || nf;
  }

  private _makeMenu(rubs: Rubric[]): IMenuItem[] {
    for (const r of rubs) {
      this._idrs[r.id] = r;
    }

    return [
      // ACTUS
      {
        link: I18N_ROUTES.site_articles_rubric(this._getRub(34)),
        external: "",
        label: this._getRub(34).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(34)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(49)),
            external: "",
            label: this._getRub(49).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(47)),
            external: "",
            label: this._getRub(47).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(48)),
            external: "",
            label: this._getRub(48).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(60)),
            external: "",
            label: this._getRub(60).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(46)),
            external: "",
            label: this._getRub(46).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(62)),
            external: "",
            label: this._getRub(62).name,
          },
        ],
      },
      // REGLEMENTATION
      {
        link: I18N_ROUTES.site_articles_rubric(this._getRub(57)),
        external: "",
        label: this._getRub(57).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(57)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(45)),
            external: "",
            label: this._getRub(45).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(44)),
            external: "",
            label: this._getRub(44).name,
          },

          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(63)),
            external: "",
            label: this._getRub(63).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(64)),
            external: "",
            label: this._getRub(64).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(65)),
            external: "",
            label: this._getRub(65).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(66)),
            external: "",
            label: this._getRub(66).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(67)),
            external: "",
            label: this._getRub(67).name,
          },
        ],
      },
      // TENDANCES
      {
        link: I18N_ROUTES.site_articles_rubric(this._getRub(54)),
        external: "",
        label: this._getRub(54).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(54)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(68)),
            external: "",
            label: this._getRub(68).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(69)),
            external: "",
            label: this._getRub(69).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(70)),
            external: "",
            label: this._getRub(70).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(71)),
            external: "",
            label: this._getRub(71).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(72)),
            external: "",
            label: this._getRub(72).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(30)),
            external: "",
            label: this._getRub(30).name,
          },
        ],
      },
      // INGREDIENTS
      {
        link: I18N_ROUTES.site_ingredients,
        external: "",
        label: this._getRub(52).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(52)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(59)),
            external: "",
            label: this._getRub(59).name,
          },
          {
            link: I18N_ROUTES.site_ingredients,
            external: "",
            label: $localize`:menulabel:Recherche ingredients`,
          },
          {
            link: I18N_ROUTES.site_ingredients_categories,
            external: "",
            label: $localize`:menulabel:Ingrédients par catégories`,
          },
        ],
      },
      // PRODUITS
      {
        link: I18N_ROUTES.site_products,
        external: "",
        label: this._getRub(53).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(53)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(73)),
            external: "",
            label: this._getRub(73).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(79)),
            external: "",
            label: this._getRub(79).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(27)),
            external: "",
            label: this._getRub(27).name,
          },
          {
            link: I18N_ROUTES.site_products,
            external: "",
            label: $localize`:menulabel:Fiches produits`,
          },
          {
            link: I18N_ROUTES.site_brands,
            external: "",
            label: $localize`:menulabel:Fiches marques`,
          },
        ],
      },
      // PACK
      {
        link: I18N_ROUTES.site_articles_rubric(this._getRub(75)),
        external: "",
        label: this._getRub(75).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(75)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(77)),
            external: "",
            label: this._getRub(77).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(78)),
            external: "",
            label: this._getRub(78).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(76)),
            external: "",
            label: this._getRub(76).name,
          },
        ],
      },
      // BASES
      {
        link: I18N_ROUTES.site_articles_rubric(this._getRub(55)),
        external: "",
        label: this._getRub(55).name,
        subItems: [
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(55)),
            external: "",
            label: $localize`:menulabel:Tous les articles`,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(22)),
            external: "",
            label: this._getRub(22).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(28)),
            external: "",
            label: this._getRub(28).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(8)),
            external: "",
            label: this._getRub(8).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(26)),
            external: "",
            label: this._getRub(26).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(5)),
            external: "",
            label: this._getRub(5).name,
          },
          {
            link: I18N_ROUTES.site_articles_rubric(this._getRub(16)),
            external: "",
            label: this._getRub(16).name,
          },
        ],
      },
    ];
  }
}
