/* tslint:disable:max-line-length */
import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild} from "@angular/core";
import {LazyComponentHostDirective} from "../lazy-component-host.directive";
import {Subject} from "rxjs";
import {WindowRefService} from "../../window-ref.service";
import {isPlatformBrowser} from "@angular/common";
import {LazyComponentService} from "../lazy-component.service";

@Component({
  selector: "cobs-lazy-component",
  templateUrl: "./lazy-component.component.pug",
  styleUrls: ["./lazy-component.component.sass"],
  standalone: true,
  imports: [LazyComponentHostDirective],
})
export class LazyComponentComponent implements OnInit, OnDestroy {
  @ViewChild(LazyComponentHostDirective, { static: true })
  ppHost!: LazyComponentHostDirective;
  @Input() component!: string;
  @Input() params: { [index: string]: any } = {};
  @Input() public visible = false;
  @Input() public idle = true;
  @Output() action = new EventEmitter<{ name: string; value: any }>();
  public loaded = false;
  private _subscriptions = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private _platform: any,
    private _lazy: LazyComponentService,
    private _elt: ElementRef,
    private _win: WindowRefService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this._platform)) {
      if (this.visible) {
        const obs = new IntersectionObserver(
          async (ob) => {
            if (!this.loaded && ob[0].isIntersecting) {
              this.loaded = true;
              obs.unobserve(this._elt.nativeElement);
              obs.disconnect();
              await this.loadComponent();
            }
          },
          {
            rootMargin: "100px 0px 100px 0px",
          }
        );
        obs.observe(this._elt.nativeElement);
      } else if (
        this.idle &&
        !this._lazy.isLoaded(this.component) &&
        this._win.nativeWindow.requestIdleCallback
      ) {
        this._win.nativeWindow.requestIdleCallback(async () => {
          await this.loadComponent();
        });
      } else {
        this.loadComponent();
      }
    } else {
      if (this.visible) {
        return;
      } else {
        await this.loadComponent();
      }
    }
  }

  public ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public async loadComponent() {
    let loadedComponent: any = null;
    let activeComponent: any;
    const viewContainerRef = this.ppHost?.viewContainerRef;
    if (!viewContainerRef) {
      console.error("No container ref available");
      return;
    }
    viewContainerRef.clear();

    switch (this.component) {
      case "cobs-cart-icon":
        loadedComponent = (
          await import(
            "../../../../billing/components/cart-icon/cart-icon.component"
          )
        ).CartIconComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-header-credits":
        loadedComponent = (
          await import(
            "../../../../site/components/header/header-credits/header-credits.component"
          )
        ).HeaderCreditsComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-header-messages":
        loadedComponent = (
          await import(
            "../../../../site/components/header/header-messages/header-messages.component"
          )
        ).HeaderMessagesComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-updater":
        loadedComponent = (
          await import("../../../../site/components/updater/updater.component")
        ).UpdaterComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-messages":
        loadedComponent = (
          await import(
            "../../../../site/components/messages/messages.component"
          )
        ).MessagesComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-automation-display":
        loadedComponent = (
          await import(
            "../../../../common/automation/automation-display/automation-display.component"
          )
        ).AutomationDisplayComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-login":
        loadedComponent = (
          await import("../../../../access/components/login/login.component")
        ).LoginComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.mode = this.params.mode;
        activeComponent.instance.next = this.params.next;
        break;
      case "cobs-article-slideshow":
        loadedComponent = (
          await import(
            "../../../../articles/components/article-slideshow/article-slideshow.component"
          )
        ).ArticleSlideshowComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.rubric = this.params.rubric;
        activeComponent.instance.unes = this.params.unes;
        break;
      case "cobs-article-headlines":
        loadedComponent = (
          await import(
            "../../../../articles/components/article-headlines/article-headlines.component"
          )
        ).ArticleHeadlinesComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.rubric = this.params.rubric;
        activeComponent.instance.headlines = this.params.headlines;
        activeComponent.instance.mode = this.params.mode;
        break;
      case "cobs-ingredient-headlines":
        loadedComponent = (
          await import(
            "../../../../ingredients/components/ingredient-headlines/ingredient-headlines.component"
          )
        ).IngredientHeadlinesComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.group = this.params.group;
        activeComponent.instance.headlines = this.params.headlines;
        activeComponent.instance.mode = this.params.mode;
        break;
      case "cobs-sitepage-display":
        loadedComponent = (
          await import(
            "../../../../common/content/components/sitepage-display/sitepage-display.component"
          )
        ).SitepageDisplayComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.slug = this.params.slug;
        break;
      case "cobs-usercontent-rubrics":
        loadedComponent = (
          await import(
            "../../../../usercontent/components/usercontent-rubrics/usercontent-rubrics.component"
          )
        ).UsercontentRubricsComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-usercontent-portfolio":
        loadedComponent = (
          await import(
            "../../../../usercontent/components/usercontent-portfolio/usercontent-portfolio.component"
          )
        ).UsercontentPortfolioComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        break;
      case "cobs-usercontent-star":
        loadedComponent = (
          await import(
            "../../../../usercontent/components/usercontent-star/usercontent-star.component"
          )
        ).UsercontentStarComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.type = this.params.type;
        activeComponent.instance.item = this.params.item;
        break;
      case "cobs-article-print":
        loadedComponent = (
          await import(
            "../../../../articles/components/article-print/article-print.component"
          )
        ).ArticlePrintComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.article = this.params.article;
        break;
      case "cobs-content-video":
        loadedComponent = (
          await import(
            "../../../../common/content/components/content-video/content-video.component"
          )
        ).ContentVideoComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.videoId = this.params.videoId;
        activeComponent.instance.mode = this.params.mode;
        activeComponent.instance.title = this.params.title;
        activeComponent.instance.restrictions = this.params.restrictions;
        break;
      case "cobs-content-audio":
        loadedComponent = (
          await import(
            "../../../../common/content/components/content-audio/content-audio.component"
          )
        ).ContentAudioComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.audioId = this.params.audioId;
        activeComponent.instance.mode = this.params.mode;
        activeComponent.instance.title = this.params.title;
        activeComponent.instance.restrictions = this.params.restrictions;
        break;
      case "cobs-suggestion-list":
        loadedComponent = (
          await import(
            "../../../../common/content/components/suggestion-list/suggestion-list.component"
          )
        ).SuggestionListComponent;
        activeComponent =
          viewContainerRef.createComponent<any>(loadedComponent);
        activeComponent.instance.article = this.params.article;
        activeComponent.instance.ingredient = this.params.ingredient;
        activeComponent.instance.product = this.params.product;
        break;
      default:
        console.error("Unknown component", this.component);
        return;
    }
    this._lazy.loaded(this.component);
  }
}
