import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { CURRENT_LOCALE_ID } from "../../i18n_setup";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export class AutomationMessage {
  public action: string;
  public title: SafeHtml;
  public message: SafeHtml;
  public data: { [index: string]: any };
  public status: string;
  public style: string;
  public buttons: string[];
  public lang: string;

  constructor(data: AutomationMessage, san: DomSanitizer) {
    this.action = data.action;
    this.title = san.bypassSecurityTrustHtml(
      (data.title as { [index: string]: string })[CURRENT_LOCALE_ID]
    );
    this.message = san.bypassSecurityTrustHtml(
      (data.message as { [index: string]: string })[CURRENT_LOCALE_ID] as string
    );
    this.data = data.data;
    this.status = "UNREAD";
    this.style = "alert-cobs";
    this.buttons = data.buttons ? data.buttons : ["closebtn"];
    this.lang = CURRENT_LOCALE_ID;
  }
}

@Injectable({
  providedIn: "root",
})
export class AutomationService {
  constructor(private _san: DomSanitizer) {}

  private _current$ = new ReplaySubject<AutomationMessage>(1);

  public get current$(): Observable<AutomationMessage> {
    return this._current$.asObservable();
  }

  public async process(res: AutomationMessage[] | null): Promise<any> {
    if (res) {
      for (const r of res) {
        if (r.action && r.action !== "") {
          const ro = new AutomationMessage(r, this._san);
          this._current$.next(ro);
        }
      }
    }
    return res;
  }
}
