
<cobs-header></cobs-header>
<div class="container bg-white">
  <cobs-site-ad mode="topsite"></cobs-site-ad>
</div>
<div class="container sitecontainer">
  <cobs-breadcrumb></cobs-breadcrumb>
  <router-outlet></router-outlet>
</div>
<cobs-footer></cobs-footer>
<cobs-lazy-component component="cobs-updater" [idle]="true"></cobs-lazy-component>
<cobs-lazy-component component="cobs-messages" [idle]="true"></cobs-lazy-component>
<cobs-lazy-component component="cobs-automation-display" [idle]="true"></cobs-lazy-component>