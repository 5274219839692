import { Injectable } from "@angular/core";
import { Collection } from "../../common/data/data-collection";
import { DataBackend } from "../../common/data/data-backend";
import { SalesService } from "./sales-service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SalesServiceService extends Collection<SalesService> {
  constructor(_backend: DataBackend) {
    super(_backend, "/api/erpcrm/catalog/services", SalesService);
  }

  public byCode(code: string): Observable<SalesService> {
    return this.action(null, "GET", "by_code", { params: { code } }).pipe(
      map((prod) => {
        const p = new SalesService();
        p.fromJson(prod);
        return p;
      })
    );
  }
}
