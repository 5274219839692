import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { firstValueFrom, Observable, ReplaySubject, timer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  private _loader!: ReplaySubject<stripe.StripeStatic>;

  constructor(
    @Inject(PLATFORM_ID) public platform: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (isPlatformBrowser(this.platform)) {
      timer(20 * 1000)
        .pipe(switchMap(() => this._load()))
        .subscribe(() => {});
    }
  }

  public async load(token: string): Promise<stripe.Stripe> {
    return (await firstValueFrom(this._load()))(token);
  }

  private _load(): Observable<any> {
    if (!this._loader) {
      const url = "https://js.stripe.com/v3/";
      this._loader = new ReplaySubject<stripe.StripeStatic>(1);
      const scriptNode = this.document.createElement("script");
      scriptNode.type = "text/javascript";
      scriptNode.onload = () => {
        this._loader.next(Stripe);
      };
      scriptNode.onerror = (err) => {
        this._loader.error(err);
      };
      scriptNode.src = url;
      this.document.head.appendChild(scriptNode);
    }
    return this._loader.asObservable();
  }
}
