import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";

export interface IPrimaryFieldMetadata extends IFieldMetadata {
  step?: number;
}

export class PrimaryFieldManager
  extends BaseFieldManager
  implements IPrimaryFieldMetadata
{
  public step?: number;
}

export function primaryField(updates: IPrimaryFieldMetadata = {}) {
  return GenericDataField(updates, "primaryField", PrimaryFieldManager);
}
