import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";
import { Content } from "../../content/services/content";

export interface IContentFieldMetadata extends IFieldMetadata {
  chapo: boolean;
  field?: string;
}

export class ContentFieldManager
  extends BaseFieldManager
  implements IContentFieldMetadata
{
  public chapo = false;

  public fromJson(data: any): Content {
    return new Content(data);
  }
}

export function contentField(
  updates: IContentFieldMetadata = { chapo: false }
) {
  return GenericDataField(updates, "contentField", ContentFieldManager);
}
