
<div class="AdSlot" #ad>
  <div class="ad" [ngClass]="mode">
    <ng-container *ngIf="ad$|async; let ad"><a [href]="ad.destination" [title]="ad.title" target="_blank" (click)="storeClick(ad)">
        <picture>
          <ng-container *ngFor="let adm of ad.medias">
            <source [srcset]="adm.srcset" [media]="adm.media">
          </ng-container><img [alt]="ad.title">
        </picture></a></ng-container>
  </div>
</div>