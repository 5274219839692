export const I18N_LANGUAGES = ["fr", "en", "de", "es"];
export const I18N_ENABLED_LANGUAGES = ["fr", "en"];
export const I18N_ALT_LANGUAGES: string[] = [];
export const CURRENT_LOCALE_ID =
  I18N_LANGUAGES.indexOf($localize`:localeid:LOCALEID`) === -1
    ? "fr"
    : $localize`:localeid:LOCALEID`;

for (const l of I18N_LANGUAGES) {
  if (l !== CURRENT_LOCALE_ID) {
    I18N_ALT_LANGUAGES.push(l);
  }
}
