
<nav class="pt-2 pb-2" aria-label="breadcrumb" *ngIf="display">
  <ol class="breadcrumb">
    <ng-container *ngFor="let p of tree">
      <li class="breadcrumb-item pointer" *ngIf="!p.current">
        <ng-container *ngIf="p.path.length==0"><a [routerLink]="p.path" [title]="p.title"><i class="icon-home"></i></a></ng-container>
        <ng-container *ngIf="p.path.length&gt;0"><a [routerLink]="p.path" [title]="p.title"><span>{{p.title.length > maxlen ? p.title.substring(0,maxlen)+"…" : p.title}}</span></a></ng-container>
      </li>
      <li class="breadcrumb-item active pointer" aria-current="page" *ngIf="p.current"><span>{{p.title.length > maxlen ? p.title.substring(0,maxlen)+"…" : p.title}}</span></li>
    </ng-container>
  </ol>
</nav>