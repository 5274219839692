
<nav class="navbar navbar-expand-lg w-100" [ngClass]="mode">
  <ul class="navbar-nav container">
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" ngbDropdown><a class="nav-link pointer" ngbDropdownToggle role="button" title="Mon espace" i18n-title><i class="icon-home me-1"></i><span class="d-xl-inline d-sm-inline d-md-none" i18n>Mon espace</span></a>
      <div class="dropdown-menu" ngbDropdownMenu><a class="dropdown-item" [routerLink]="['']" i18n title="A la une" i18n-title>A la une</a><a class="dropdown-item" [routerLink]="['']" [fragment]="'perso'" i18n title="Ma veille personnalisée" i18n-title>Ma veille personnalisée</a><a class="dropdown-item" [routerLink]="['']" [fragment]="'portfolio'" i18n title="Mon portfolio" i18n-title>Mon portfolio</a></div>
    </li>
    <ng-container *ngFor="let i of items">
      <ng-container *ngIf="!i.subItems">
        <li class="nav-item" *ngIf="i.external"><a class="nav-link" [href]="i.external" (click)="clicked.emit()" [title]="i.label"><span *ngIf="i.label">{{i.label}}</span></a></li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!i.external"><a class="nav-link" [routerLink]="i.link" (click)="clicked.emit()" [title]="i.label"><span *ngIf="i.label">{{i.label}}</span></a></li>
      </ng-container>
      <li class="nav-item" routerLinkActive="active" *ngIf="i.subItems" ngbDropdown><a class="nav-link pointer" ngbDropdownToggle role="button" [title]="i.label"><span *ngIf="i.label">{{i.label}}</span></a>
        <div class="dropdown-menu" ngbDropdownMenu>
          <ng-container *ngFor="let s of i.subItems"><a class="dropdown-item" [routerLink]="s.link" (click)="clicked.emit()" *ngIf="!s.external" [title]="s.label"><span *ngIf="s.label">{{s.label}}</span></a><a class="dropdown-item" [href]="s.external" (click)="clicked.emit()" *ngIf="s.external" [title]="s.label"><span *ngIf="s.label">{{s.label}}</span></a></ng-container>
        </div>
      </li>
    </ng-container>
    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" ngbDropdown><a class="nav-link pointer" (click)="clicked.emit()" [routerLink]="I18N_ROUTES.site_eshop_home" title="Boutique CosmeticOBS" i18n-title><i class="icon-basket d-none d-xl-inline me-1"></i>
        <ng-container i18n>E‑Librairie</ng-container></a></li>
    <li class="nav-item search"><a class="nav-link" [routerLink]="I18N_ROUTES.site_search" (click)="clicked.emit()" title="Rechercher sur le site" i18n-title><span class="me-3" *ngIf="mode=='mobile'" i18n>Recherche</span><i class="icon-search"></i></a></li>
  </ul>
</nav>