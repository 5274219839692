import "reflect-metadata";
import { UntypedFormControl, ValidatorFn, Validators } from "@angular/forms";
import { DataModel } from "../data-model";

export const PROPERTY_METADATA_KEY = Symbol("propertyMetadata");

export interface IFieldMetadata {
  name?: string;
  description?: string;
  required?: boolean;
  readonly?: boolean;
  default?: any;
}

export class BaseFieldManager implements IFieldMetadata {
  public name!: string;
  public description!: string;
  public required = false;
  public readonly = false;
  public default: any;

  constructor(params: any) {
    Object.assign(this, params);
  }

  public get validators(): ValidatorFn[] {
    const v: ValidatorFn[] = [];
    if (this.required) {
      v.push(Validators.required);
    }
    return v;
  }

  public get fname(): string {
    return this.name.split("__")[1];
  }

  public formControl(instance: DataModel) {
    return new UntypedFormControl(
      (instance as any)[this.fname],
      this.validators
    );
  }

  protected getValidators(): ValidatorFn[] {
    const v: ValidatorFn[] = [];
    if (this.required) {
      v.push(Validators.required);
    }
    return v;
  }
}

function ownKeys(target: any): any {
  return Object.getOwnPropertyNames(target).concat(
    Object.getOwnPropertySymbols(target) as any
  );
}

type BaseFieldManagerConstructor<T extends BaseFieldManager> = new (
  params: any
) => T;

export function GenericDataField<
  MD extends IFieldMetadata,
  MG extends BaseFieldManager
>(updates: MD, type: string, manager: BaseFieldManagerConstructor<MG>) {
  return (target: any, propertyKey: string | symbol) => {
    const allMetadata =
      Reflect.getMetadata(PROPERTY_METADATA_KEY, target) || {};
    const pk = `${
      target.constructor._type || target.constructor.name
    }__${String(propertyKey)}`;
    allMetadata[pk] = allMetadata[pk] || {};
    for (const key of ownKeys(updates as any)) {
      allMetadata[pk][key] = (updates as any)[key];
    }
    if (updates.name === undefined) {
      allMetadata[pk].name = pk;
    }
    allMetadata[pk].type = type;
    allMetadata[pk].manager = new manager(allMetadata[pk]);
    Reflect.defineMetadata(PROPERTY_METADATA_KEY, allMetadata, target);
  };
}
