<!DOCTYPE html>
<div class="full d-none d-lg-block">
  <header>
    <cobs-header-language mode="full"></cobs-header-language>
    <cobs-header-social mode="full"></cobs-header-social>
    <div class="logo pointer" [routerLink]="I18N_ROUTES.site_home"><img [src]="logoUrl" alt="Logo CosmeticOBS" i18n-alt width="500" height="66"></div>
    <cobs-lazy-component component="cobs-header-credits"></cobs-lazy-component>
    <cobs-lazy-component component="cobs-header-messages"></cobs-lazy-component>
    <cobs-lazy-component component="cobs-cart-icon"></cobs-lazy-component>
    <cobs-header-userinfos class="me-2" mode="full"></cobs-header-userinfos><a class="btn btn-maj btn-secondary btn-p3 me-2" i18n-href href="https://calendly.com/cosmeticobs-simona-42/pro-abonnements-demo" target="_blank" i18n>Démo</a>
    <cobs-header-subscribe mode="full"></cobs-header-subscribe>
  </header>
  <cobs-header-menu mode="full"></cobs-header-menu>
</div>
<ng-template #sidemenu let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">
      <div class="row g-0">
        <div class="col-8"><img class="img-fluid" [src]="logoUrl" alt="Logo CosmeticOBS" i18n-alt width="500" height="66"></div>
        <div class="col-4">
          <button class="btn-close float-end" type="button" aria-label="Close" (click)="toggleMenu(true)"></button>
        </div>
      </div>
    </h4>
  </div>
  <div class="offcanvas-body">
    <div class="menu">
      <cobs-header-menu mode="mobile" (clicked)="toggleMenu(true)"></cobs-header-menu>
      <cobs-header-userinfos mode="mobile" (clicked)="toggleMenu(true)"></cobs-header-userinfos>
      <cobs-header-subscribe mode="mobile" (clicked)="toggleMenu(true)"></cobs-header-subscribe><a class="btn btn-maj btn-secondary btn-p3 my-1 me-2 w-100" i18n-href href="https://calendly.com/cosmeticobs-simona-42/pro-abonnements-demo" target="_blank" i18n>Prendre RDV pour une démo</a>
      <cobs-header-language mode="mobile"></cobs-header-language>
      <cobs-header-social mode="mobile"></cobs-header-social>
    </div>
  </div>
</ng-template>
<div class="mobile d-block d-lg-none">
  <header>
    <div class="top p-2">
      <div class="logo pointer" (click)="toggleMenu()"><img [src]="logoUrl" alt="Logo CosmeticOBS" i18n-alt width="500" height="66"></div>
      <div class="action d-flex flex-row align-items-center">
        <cobs-lazy-component component="cobs-header-credits"></cobs-lazy-component>
        <cobs-lazy-component component="cobs-header-messages"></cobs-lazy-component>
        <cobs-lazy-component component="cobs-cart-icon"></cobs-lazy-component><i class="icon-dot-3 icon-2x" role="button" (click)="toggleMenu()"></i>
      </div>
    </div>
  </header>
</div>