import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";

export interface IDateFieldMetadata extends IFieldMetadata {
  autoNow?: boolean;
  autoNowAdd?: boolean;
}

export interface IDatetimeFieldMetadata extends IDateFieldMetadata {
  timezone?: string;
}

export class DateFieldManager extends BaseFieldManager {
  public autoNow = false;
  public autoNowAdd = false;

  public fromJson(data: string | null): Date | null {
    if (data === null) {
      return null;
    }
    return new Date(data);
  }
}

export class DatetimeFieldManager
  extends DateFieldManager
  implements IDatetimeFieldMetadata
{
  public timezone = "Europe/Paris";
}

export function dateField(updates: IDateFieldMetadata = {}) {
  return GenericDataField(updates, "dateField", DateFieldManager);
}

export function datetimeField(updates: IDatetimeFieldMetadata = {}) {
  return GenericDataField(updates, "datetimeField", DatetimeFieldManager);
}
