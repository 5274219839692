import {
  Content,
  ContentLink,
  ContentMedia,
} from "../../common/content/services/content";
import { Image } from "../../common/display/image";
import { detailsField, imageField } from "../../common/data/fields";
import { contentField } from "../../common/data/fields/content";
import { ArticleBase } from "./article.base";
import { CURRENT_LOCALE_ID } from "../../i18n_setup";

export class Article extends ArticleBase {
  static readonly _type: string = "Article";

  @imageField()
  public icon_details!: Image;

  @detailsField({ deserialize: (d) => d.map((i: Image) => new Image(i)) })
  public icons_details!: Image[];

  @contentField()
  public chapo_details!: Content;

  @contentField()
  public content_details!: Content;

  @detailsField({ model: ContentLink, many: true })
  public links!: ContentLink[];

  @detailsField({ model: ContentMedia, many: true })
  public carousel!: ContentMedia[];

  public iconImage(mode: string): Image | null {
    if (!this.icons_details) {
      if (this.icon_details) {
        return this.icon_details;
      }
      return null;
    }
    if (mode === "og:image") {
      for (const i of this.icons_details) {
        if (i.mode !== "icon" && i.lang == CURRENT_LOCALE_ID) {
          return i;
        }
      }
      for (const i of this.icons_details) {
        if (i.mode !== "icon" && i.lang == null) {
          return i;
        }
      }
      for (const i of this.icons_details) {
        if (i.mode == "icon" && i.lang == CURRENT_LOCALE_ID) {
          return i;
        }
      }
      for (const i of this.icons_details) {
        if (i.mode == "icon" && i.lang == null) {
          return i;
        }
      }
    }
    return this.icons_details[0];
  }

  public icon(mode: string): string | null {
    const i = this.iconImage(mode);
    if (i) {
      return i.rurl("lg");
    }
    return null;
  }
}
