export function getRandomString(length = 20): string {
  let r = "";
  while (r.length < length) {
    r += Math.ceil(Math.random() * Number.MAX_SAFE_INTEGER).toString(36);
  }
  return r.substr(Math.floor(r.length / 2 - length / 2), length);
}

export function getCheckableRandomString(length = 20): string {
  let device_id = getRandomString(length - 1);
  let ct = 0;
  for (const l of device_id) {
    ct += l.charCodeAt(0);
  }
  device_id += (ct % 36).toString(36);
  return device_id;
}

export function checkRandomString(token: string, length: number): boolean {
  if (token.length !== length) {
    return false;
  }
  let ct = 0;
  for (let i = 0; i < length - 1; i++) {
    ct += token.charCodeAt(i);
  }
  return token[length - 1] === (ct % 36).toString(36);
}

export interface IItemWithPriority {
  exposition: number;
}

export function weightedRandom<T extends IItemWithPriority>(
  prob: T[]
): T | null {
  let sum = 0;
  let tw = 0;

  if (prob.length === 0) {
    return null;
  }
  for (const i of prob) {
    tw += i.exposition;
  }
  const r = Math.random() * tw;
  for (const i of prob) {
    sum += i.exposition;
    if (r <= sum) {
      return i;
    }
  }
  return prob[0];
}
