import { build, release, version } from "./version";

export const environment = {
  production: true,
  staging: false,
  VERSION: version,
  BUILD: build,
  RELEASE: release,
  MODE: "production",
  API_URL: "https://api.cosmeticobs.com",
  STATS_URL: "https://data.cosmeticobs.xyz",
  MAIN_URL: "https://cosmeticobs.com",
  DEPLOY_URL_BASE: "", // https://dkl1ruubfmedb.cloudfront.net/production",
  USE_RESPONSIVE_MEDIAS: true,
  MEDIA_BASE: "https://cobs-data.s3.amazonaws.com",
  RESPONSIVE_MEDIA_BASE: "https://d2aabgjce9enf.cloudfront.net",
  stripeToken: "pk_live_ffvpODiHzSOxLm2AebGeHwpt",
  SOCIAL_KEYS: {
    "google-oauth2":
      "550064710506-9th2ar2hc2p31ph6gefi389jkkimnvks.apps.googleusercontent.com",
    facebook: "1877851559121227",
    twitter: "",
    "linkedin-oauth2": "77lmb79x18edx1",
  },
  VAPID_PUBLIC_KEY:
    "BEAJXgBiazWKPNCpHQlIlWuPDIhjqW4J1G6t1Tv6pu9LnHI8ch39nK6Se5lRXmzs_wY998gv-ZqcIOPIL4RfvNY",
  CORDOVA: false,
};
