import { primaryField } from "./fields/primaryfield";
import { BaseFieldManager, PROPERTY_METADATA_KEY } from "./fields/base";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Collection } from "./data-collection";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataModel {
  static readonly _type: string = "DataModel";

  @primaryField({})
  public id!: number;

  constructor(protected _coll?: Collection<any>) {}

  public get __fields(): any {
    const out: any = {};
    const md = Reflect.getMetadata(PROPERTY_METADATA_KEY, this);
    for (const f of Object.keys(md)) {
      if (
        f.indexOf((this.constructor as any)._type + "__") === 0 ||
        f.indexOf((this.constructor as any)._type + "Base__") === 0
      ) {
        out[f.split("__")[1]] = md[f];
      }
    }
    return out;
  }

  public fromJson(data: any) {
    const fl = this.__fields;
    if (!fl) {
      console.warn("Bulk object update, please fix this; object : ", this);
      Object.assign(this, data);
    } else {
      for (const f of Object.keys(fl)) {
        if (data[f] !== undefined) {
          if (fl[f].manager.fromJson) {
            (this as any)[f] = fl[f].manager.fromJson(data[f]);
          } else {
            (this as any)[f] = data[f];
          }
        }
      }
    }
  }

  public toJson(fields?: string[]) {
    const out: any = {};
    const fl = this.__fields;
    for (const f of Object.keys(fl)) {
      if (!fields || fields.length === 0) {
        out[f] = (this as any)[f];
      } else {
        if (fields.indexOf(f) !== -1) {
          out[f] = (this as any)[f];
        }
      }
    }
    return out;
  }

  public action<T>(
    method: "POST" | "PATCH" | "GET" | "DELETE" | "PUT",
    name: string,
    body: any,
    options?: {
      update?: boolean;
      params?: { [index: string]: string };
      headers?: { [index: string]: string };
    }
  ): Observable<T> {
    if (this._coll === undefined) {
      throw Error("Missing collection");
    }
    if (!options) {
      options = {};
    }
    if (options.update === undefined) {
      options.update = false;
    }
    if (options.params === undefined) {
      options.params = {};
    }
    if (options.headers === undefined) {
      options.headers = {};
    }
    return this._coll
      .action(this, method, name, {
        body,
        params: options.params,
        headers: options.headers,
      })
      .pipe(
        map((item) => {
          if (options && options.update) {
            this.fromJson(item);
            return this;
          }
          return item;
        })
      );
  }

  public F(field: string): BaseFieldManager {
    return this.__fields[field].manager;
  }

  public FC(field: string | string[]): UntypedFormControl | UntypedFormGroup {
    if (Array.isArray(field)) {
      const fg: { [index: string]: UntypedFormControl } = {};
      for (const f of field) {
        fg[f] = this.FC(f) as UntypedFormControl;
      }
      return new UntypedFormGroup(fg);
    }
    return this.__fields[field].manager.formControl(this);
  }
}
