import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { StylesService } from "./common/styles/styles.service";
import { AnalyticsService } from "./common/stats/analytics.service";
import { StripeService } from "./common/stats/stripe.service";
import { isPlatformBrowser } from "@angular/common";
import { LazyComponentComponent } from "./common/display/lazyness/lazy-component/lazy-component.component";
import { FooterComponent } from "./site/components/footer/footer.component";
import { RouterOutlet } from "@angular/router";
import { BreadcrumbComponent } from "./site/components/breadcrumb/breadcrumb.component";
import { SiteAdComponent } from "./common/ads/site-ad/site-ad.component";
import { HeaderComponent } from "./site/components/header/header.component";

@Component({
  selector: "cobs-root",
  templateUrl: "./app.component.pug",
  styleUrls: ["./app.component.sass"],
  standalone: true,
  imports: [
    HeaderComponent,
    SiteAdComponent,
    BreadcrumbComponent,
    RouterOutlet,
    FooterComponent,
    LazyComponentComponent,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) public platform: any,
    private _styles: StylesService,
    private _analytics: AnalyticsService,
    private _stripe: StripeService
  ) {}

  public ngOnInit(): void {
    this._styles.load("main");
    if (isPlatformBrowser(this.platform)) {
      this._analytics.trackPages();
    }
  }
}
