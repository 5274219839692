import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";

export interface IIntegerFieldMetadata extends IFieldMetadata {
  min?: number;
  max?: number;
}

export interface IDecimalFieldMetadata extends IFieldMetadata {
  min?: number;
  max?: number;
  factor: number;
}

export class IntegerFieldManager
  extends BaseFieldManager
  implements IIntegerFieldMetadata
{
  public min?: number;
  public max?: number;
}

export class DecimalFieldManager
  extends IntegerFieldManager
  implements IDecimalFieldMetadata
{
  public factor!: number;
}

export function integerField(updates: IIntegerFieldMetadata = {}) {
  return GenericDataField(updates, "integerField", IntegerFieldManager);
}

export function decimalField(updates: IDecimalFieldMetadata = { factor: 100 }) {
  return GenericDataField(updates, "decimalField", IntegerFieldManager);
}
