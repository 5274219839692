import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
  selector: "cobs-header-social",
  templateUrl: "./header-social.component.pug",
  styleUrls: ["./header-social.component.sass"],
  standalone: true,
  imports: [NgIf],
})
export class HeaderSocialComponent implements OnInit {
  @Input() public mode: "full" | "mobile" = "full";

  constructor() {}

  ngOnInit(): void {}
}
