import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CURRENT_LOCALE_ID } from "../../i18n_setup";

@Injectable({
  providedIn: "root",
})
export class DeployUrlService {
  constructor() {}

  public url(base: string): string {
    if (base.length === 0) {
      base = "/";
    }
    if (base[0] !== "/") {
      base = "/" + base;
    }
    if (environment.MODE === "devel") {
      return `/${CURRENT_LOCALE_ID}${base}`;
    } else if (environment.CORDOVA) {
      return `${environment.DEPLOY_URL_BASE}${base}`;
    }
    return `/${CURRENT_LOCALE_ID}${base}`;
  }
}
