import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from "@angular/router";

import { BreadcrumbService } from "../../services/breadcrumb.service";
import { combineLatest } from "rxjs";
import { filter, map, share } from "rxjs/operators";
import { NgFor, NgIf } from "@angular/common";

export interface IPathTreeItem {
  path: string;
  title: string;
  current: boolean;
}

@Component({
  selector: "cobs-breadcrumb",
  templateUrl: "./breadcrumb.component.pug",
  styleUrls: ["./breadcrumb.component.sass"],
  // changeDetection: ChangeDetectionStrategy.OnPush
  standalone: true,
  imports: [NgIf, NgFor, RouterLink],
})
export class BreadcrumbComponent implements OnInit {
  public tree: IPathTreeItem[] = [];
  @Input() public maxlen = 40;
  public display = true;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _breadcrumbData: BreadcrumbService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const lastRoute = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this._route),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === "primary"),
      share()
    );
    const breadcrumbData = this._breadcrumbData.data$;
    combineLatest([lastRoute, breadcrumbData]).subscribe((res) => {
      const [route, data] = res;
      this.getRouteTree(route, data);
    });
  }

  public getRouteTree(r: ActivatedRoute, data: any): void {
    const tree: IPathTreeItem[] = [];
    const url: string[] = [];
    this.display = !(r.snapshot.data.breadcrumb === false);
    for (const p of r.snapshot.pathFromRoot) {
      const rtd: IPathTreeItem = { title: "", path: "", current: false };
      // Retrieve path data from route config
      if (p.routeConfig && p.routeConfig.data) {
        // There is a route config
        if (p.routeConfig.data.title) {
          if (typeof p.routeConfig.data.title === "function") {
            try {
              rtd.title = p.routeConfig.data.title({ route: p, data });
            } catch (e) {
              rtd.title = "…";
            }
          } else {
            rtd.title = p.routeConfig.data.title;
          }
        }
      } else {
        // No route config
        if (p.parent !== null) {
          console.warn("Missing route config for", p);
        }
        rtd.title = p.url.join(" ");
      }
      // Url management, add all paths if existing
      if (p.url.length > 0) {
        url.push(p.url.join("/"));
        rtd.path = url.join("/");
        // fix missing first slash
        if (rtd.path.length > 0 && rtd.path[0] !== "/") {
          rtd.path = "/" + rtd.path;
        }
      }

      // Don't add root route / unconfigured routes / skipped routes
      if (
        !(
          (p.routeConfig && p.routeConfig.data && p.routeConfig.data.skip) ||
          p.parent === null ||
          !p.routeConfig
        )
      ) {
        tree.push(rtd);
      }
    }
    if (tree.length > 0) {
      tree[tree.length - 1].current = true;
    }
    this.tree = tree;
    this._cdr.detectChanges();
  }
}
