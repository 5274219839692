
<ng-container *ngIf="auth">
  <ng-container *ngIf="!auth.isAnonymous">
    <!-- TODO: for mobile mode, use external view, no dropdown-->
    <div class="knownuser" ngbDropdown display="dynamic" #drop1="ngbDropdown" [ngClass]="mode"><a class="btn btn-grey2 btn-maj btn-p3" ngbDropdownToggle><i class="icon-user me-3"></i><span class="userinfos">{{auth.lastname}}<span class="firstname ms-2">{{auth.firstname}}</span></span>
        <div class="badge bg-danger subscription ms-1" *ngIf="auth.isPro">PRO</div></a>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <h6 class="dropdown-header">{{auth.lastname}} {{auth.firstname}}</h6><a class="dropdown-item" [routerLink]="I18N_ROUTES.site_account"><i class="icon-cog-alt me-2"></i><span i18n>Mon compte</span></a><a class="dropdown-item" [routerLink]="I18N_ROUTES.site_account_contracts"><i class="icon-cog-alt me-2"></i><span i18n>Mon abonnement</span></a>
        <div class="dropdown-divider"></div><a class="dropdown-item" [routerLink]="I18N_ROUTES.site_home" fragment="perso"><i class="icon-home me-2"></i><span i18n>Ma page d'accueil</span></a><a class="dropdown-item" [routerLink]="I18N_ROUTES.site_home" fragment="portfolio"><i class="icon-bookmark me-2"></i><span i18n>Mon portfolio</span></a><a class="dropdown-item" [routerLink]="I18N_ROUTES.site_account_usercontent"><i class="icon-bookmark me-2"></i><span i18n>Mon contenu préféré</span></a>
        <div class="dropdown-divider"></div><a class="dropdown-item text-danger" [routerLink]="I18N_ROUTES.site_access_logout"><i class="icon-logout me-2"></i><span i18n>Déconnexion</span></a>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="auth.isAnonymous">
    <!-- Unknown user-->
    <!-- TODO: for mobile mode, use external view, no dropdown--><a class="btn btn-primary my-2 w-100" [routerLink]="I18N_ROUTES.site_access_login" *ngIf="mode=='mobile'"><i class="icon-user me-3"></i>
      <ng-container i18n>SE CONNECTER</ng-container></a>
    <div class="loginbox" ngbDropdown [autoClose]="'outside'" #drop2="ngbDropdown" [ngClass]="mode" *ngIf="mode!='mobile'"><a class="btn btn-grey2 btn-maj btn-p3" ngbDropdownToggle><i class="icon-user me-3"></i>
        <ng-container i18n>SE CONNECTER</ng-container></a>
      <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <cobs-lazy-component component="cobs-login" [params]="{mode: 'dropdown'}"></cobs-lazy-component>
      </div>
    </div>
  </ng-container>
</ng-container>