import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { SeoService } from "../../../../common/seo/seo.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { NgClass } from "@angular/common";

@Component({
  selector: "cobs-header-language",
  templateUrl: "./header-language.component.pug",
  styleUrls: ["./header-language.component.sass"],
  standalone: true,
  imports: [NgClass],
})
export class HeaderLanguageComponent implements OnInit, OnDestroy {
  @Input() public mode: "full" | "mobile" = "full";
  public frUrl!: string;
  public enUrl!: string;
  private _subscriptions = new Subject<void>();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private _seo: SeoService
  ) {}

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public ngOnInit(): void {
    this._seo.data.pipe(takeUntil(this._subscriptions)).subscribe((data) => {
      if (data.alternate["fr"]) {
        this.frUrl = data.alternate["fr"];
      } else {
        this.frUrl = environment.MAIN_URL + "/fr/";
      }
      if (data.alternate["en"]) {
        this.enUrl = data.alternate["en"];
      } else {
        this.enUrl = environment.MAIN_URL + "/en/";
      }
      if (environment.MODE === "devel") {
        this.frUrl = this.frUrl.replace("14441", "14440");
        this.enUrl = this.enUrl.replace("14440", "14441");
      }
    });
  }
}
