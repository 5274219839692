import { DataModel } from "./data-model";
import { DataBackend } from "./data-backend";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IQuerysetOptions, Queryset } from "./data-query";
import { first, tap } from "rxjs/operators";

export type IHttpMethod = "POST" | "GET" | "DELETE" | "PUT" | "PATCH";

export interface IActionParams {
  body?: any;
  headers?: { [index: string]: string };
  params?: { [index: string]: string };
}

export interface ICollectionCacheParams {
  transferState?: {
    enabled: boolean;
    maxTime: number;
    maxCount: number;
  };
}

export class Collection<T extends DataModel> {
  public useCache = true;

  constructor(
    protected _backend: DataBackend,
    protected path: string,
    protected model: any
  ) {
    // FIXME: any here
  }

  fromJson(data: any, many: boolean = false): T | any {
    if (data && data.id !== undefined) {
      const m = new this.model(this);
      m.fromJson(data);
      return m;
    } else {
      if (many && data && data.length) {
        const out: T[] = [];
        for (const r of data) {
          const ri = new this.model(this);
          ri.fromJson(r);
          out.push(ri);
        }
        return out;
      }
      return data;
    }
  }

  public queryset(options: IQuerysetOptions = {}): Queryset<T> {
    return new Queryset<T>(this, options);
  }

  public fetch(
    id: number,
    suffix: string = "",
    params: { [index: string]: string } = {},
    headers: { [index: string]: string } = {}
  ): Observable<T> {
    return this._backend.get(this, this.getUrl(id, suffix), params, headers);
  }

  public action(
    model: T | null,
    method: IHttpMethod,
    action: string,
    params: IActionParams
  ): Observable<any> {
    return this._backend.action(
      this,
      method,
      this.getUrl(model ? model.id : undefined, model ? "/" + action : action),
      params.body,
      params.params,
      params.headers
    );
  }

  public save(model: T, updateModel: boolean = true): Observable<T> {
    if (model.id !== undefined) {
      return this._backend
        .patch(this, this.getUrl(model.id), model.toJson())
        .pipe(
          tap((res) => {
            if (updateModel) {
              model.fromJson(res);
            }
          }),
          first()
        );
    } else {
      return this._backend.post(this, this.getUrl(), model.toJson()).pipe(
        tap((res) => {
          if (updateModel) {
            model.fromJson(res);
          }
        }),
        first()
      );
    }
  }

  public update(
    model: T,
    fields: string[] = [],
    updateModel: boolean = true
  ): Observable<T> {
    if (model.id !== undefined) {
      return this._backend
        .patch(this, this.getUrl(model.id), model.toJson(fields))
        .pipe(
          tap((res) => {
            if (updateModel) {
              model.fromJson(res);
            }
          }),
          first()
        );
    } else {
      return this._backend.post(this, this.getUrl(), model.toJson(fields)).pipe(
        tap((res) => {
          if (updateModel) {
            model.fromJson(res);
          }
        }),
        first()
      );
    }
  }

  public delete(model: T): Observable<any> {
    return this._backend.delete(this, this.getUrl(model.id)).pipe(
      tap((res) => {}),
      first()
    );
  }

  public list(
    query: { [index: string]: string } = {},
    suffix: string = ""
  ): Observable<any> {
    return this._backend.get(this, this.getUrl(undefined, suffix), query);
  }

  protected getUrl(id?: number, suffix: string = ""): string {
    if (id === undefined || id === null) {
      if (suffix) {
        return `${environment.API_URL}${this.path}/${suffix}`;
      } else {
        return `${environment.API_URL}${this.path}`;
      }
    }
    return `${environment.API_URL}${this.path}/${id}${suffix}`;
  }
}
