import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { I18N_ROUTES } from "../../../../app-routes";
import { RubricService } from "../../../../articles/services/rubric.service";
import { Rubric } from "../../../../articles/services/rubric";
import {
  IMenuItem,
  MainMenuService,
} from "../../../services/main-menu.service";
import { combineLatest } from "rxjs";
import { first } from "rxjs/operators";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { NgClass, NgFor, NgIf } from "@angular/common";
import {
  NgbDropdown,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbNavbar,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "cobs-header-menu",
  templateUrl: "./header-menu.component.pug",
  styleUrls: ["./header-menu.component.sass"],
  standalone: true,
  imports: [
    NgbNavbar,
    NgClass,
    RouterLinkActive,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    RouterLink,
    NgFor,
    NgIf,
  ],
})
export class HeaderMenuComponent implements OnInit {
  @Input() public mode: "full" | "mobile" = "full";
  @Output() public clicked = new EventEmitter<void | "open" | "close">();
  public I18N_ROUTES = I18N_ROUTES;
  public rubrics!: Rubric[];
  public items: IMenuItem[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _menu: MainMenuService,
    private _rubrics: RubricService
  ) {}

  public ngOnInit(): void {
    combineLatest([this._rubrics.all$, this._menu.current$])
      .pipe(first())
      .subscribe(([rubs, mnu]) => {
        this.rubrics = rubs;
        this.items = mnu;
      });
  }
}
