import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

/**
 * LocalStorage wrapper; if no local storage can be used,
 * data is stored locally.
 */
@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _local: { [index: string]: any } = {};
  private _localStorageAvailable?: boolean;

  constructor(@Inject(PLATFORM_ID) private platform: any) {}

  /**
   * Check if localStorage is available, caches result and return it.
   */
  public get haveLocalStorage(): boolean {
    if (this._localStorageAvailable === undefined) {
      if (isPlatformBrowser(this.platform)) {
        try {
          localStorage.setItem("___TESTLS", "___TESTLS");
          localStorage.removeItem("___TESTLS");
          this._localStorageAvailable = true;
        } catch (e) {
          this._localStorageAvailable = false;
        }
      } else {
        this._localStorageAvailable = false;
      }
    }
    return this._localStorageAvailable;
  }

  public getRawItem(key: string): string | null {
    if (this.haveLocalStorage) {
      return localStorage.getItem(key);
    } else {
      return this._local[key] === undefined ? null : this._local[key];
    }
  }

  public setRawItem(key: string, value: string): void {
    if (this.haveLocalStorage) {
      localStorage.setItem(key, value);
    } else {
      this._local[key] = value;
    }
  }

  public getItem(key: string): any {
    const val = this.getRawItem(key);
    if (val === null) {
      return null;
    }
    try {
      return JSON.parse(val);
    } catch (e) {
      console.error(
        "Error decoding storage key",
        key,
        "value",
        val,
        ": error",
        e
      );
      return val;
    }
  }

  public setItem(key: string, value: any): void {
    if (this.haveLocalStorage) {
      return localStorage.setItem(key, JSON.stringify(value));
    } else {
      this._local[key] = JSON.stringify(value);
    }
  }

  public removeItem(key: string): any {
    if (this.haveLocalStorage) {
      return localStorage.removeItem(key);
    } else {
      delete this._local[key];
    }
  }

  public clear(key: string): any {
    if (this.haveLocalStorage) {
      return localStorage.clear();
    } else {
      this._local = {};
    }
  }
}
