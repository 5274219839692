import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18N_ROUTES } from "../../../../app-routes";
import { RouterLink } from "@angular/router";
import { NgClass } from "@angular/common";

@Component({
  selector: "cobs-header-subscribe",
  templateUrl: "./header-subscribe.component.pug",
  styleUrls: ["./header-subscribe.component.sass"],
  standalone: true,
  imports: [NgClass, RouterLink],
})
export class HeaderSubscribeComponent implements OnInit {
  @Input() public mode: "full" | "mobile" = "full";
  @Output() public clicked = new EventEmitter<any>();
  public I18N_ROUTES = I18N_ROUTES;

  constructor() {}

  ngOnInit(): void {}
}
