import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";
import { ValidatorFn, Validators } from "@angular/forms";

export interface ICharFieldMetadata extends IFieldMetadata {
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp | string;
  choices?: string[];
}

export interface IEmailFieldMetadata extends ICharFieldMetadata {
  validate?: boolean;
}

export interface ITextFieldMetadata extends ICharFieldMetadata {
  syntax?: string;
}

export class CharFieldManager extends BaseFieldManager {
  public minLength?: number;
  public maxLength?: number;
  public choices?: string[];
  public pattern?: RegExp | string;

  constructor(params: any) {
    super(params);
  }

  public get validators(): ValidatorFn[] {
    const v = super.getValidators();
    if (this.minLength) {
      v.push(Validators.minLength(this.minLength));
    }
    if (this.maxLength) {
      v.push(Validators.maxLength(this.maxLength));
    }
    if (this.pattern) {
      v.push(Validators.pattern(this.pattern));
    }
    // TODO: add choices validator ??
    return v;
  }
}

export class EmailFieldManager
  extends CharFieldManager
  implements ICharFieldMetadata
{
  public validate?;

  constructor(params: any) {
    super(params);
    if (this.validate === undefined) {
      this.validate = true;
    }
  }

  public get validators(): ValidatorFn[] {
    const v = super.getValidators();
    if (this.validate) {
      v.push(Validators.email);
    }
    return v;
  }
}

export class TextFieldManager extends CharFieldManager {
  public syntax?: string;
}

export function charField(updates: ICharFieldMetadata = {}) {
  return GenericDataField(updates, "charField", CharFieldManager);
}

export function emailField(updates: IEmailFieldMetadata = {}) {
  return GenericDataField(updates, "emailField", EmailFieldManager);
}

export function textField(updates: ITextFieldMetadata = {}) {
  return GenericDataField(updates, "textField", TextFieldManager);
}
