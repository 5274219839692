import { DataModel } from "../../common/data/data-model";
// noinspection TypeScriptPreferShortImport
import { primaryField } from "../../common/data/fields/primaryfield";
// noinspection TypeScriptPreferShortImport
import { charField } from "../../common/data/fields/stringfields";
// noinspection TypeScriptPreferShortImport
// noinspection TypeScriptPreferShortImport
import { imageField } from "../../common/data/fields/image";
// noinspection TypeScriptPreferShortImport
// noinspection TypeScriptPreferShortImport
import { detailsField } from "../../common/data/fields/relations";
import { Image } from "../../common/display/image";

export interface IDistribution {
  id: number;
  name: string;
  country: string;
}

export interface IBrandContact {
  id: number;
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  email: string;
  mobile: string;
  phone: string;
}

export class BrandBase extends DataModel {
  static readonly _type: string = "BrandBase";

  @primaryField()
  public id!: number;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @charField()
  public meta_title!: string;

  @charField()
  public meta_keywords!: string;

  @charField()
  public meta_description!: string;

  @charField()
  public website!: string;

  @imageField()
  public icon_details!: Image;

  @detailsField({ many: true })
  public distributions_details!: IDistribution[];

  @detailsField({ many: true })
  public contacts_details!: IBrandContact[];

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }
}
