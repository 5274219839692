import { inject } from "@angular/core";
import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Auth, AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { first, mergeMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DeviceIdService } from "../../common/stats/device-id.service";

export const httptokenInterceptor = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const auth = inject(AuthService);
  const device = inject(DeviceIdService);
  // Avoid request loop on /api/auth
  // SEE: add here a list of non-authenticated getUrl
  if (request.url.indexOf(environment.API_URL) === -1) {
    return next(request);
  }
  if (
    request.url.indexOf("/api/auth") > 0 ||
    request.url.indexOf("/api/users/register") > 0 ||
    request.url.indexOf("/api/social/connect") > 0
  ) {
    return next(request);
  }
  return auth.current$().pipe(
    first(),
    mergeMap((auth: Auth) => {
      if (!auth.isAnonymous && auth.isActive) {
        request = request.clone({
          setHeaders: {
            "x-token": `${auth.token}`,
            "x-device": device.current,
          },
        });
      }
      return next(request);
    })
  );
};
