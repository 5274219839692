import {
  booleanField,
  charField,
  datetimeField,
  detailsField,
  foreignKeyField,
  imageField,
  integerField,
  manyToManyField,
  primaryField,
} from "../../common/data/fields";
import { DataModel } from "../../common/data/data-model";
import { Brand } from "./brand";
import { Line } from "./line";
import { Image } from "../../common/display/image";
import { ProductCategory } from "./category";
import { BrandBase } from "./brand.base";

export class ProductBase extends DataModel {
  static readonly _type: string = "ProductBase";

  @primaryField()
  public id!: number;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @foreignKeyField({ related: "Brand" })
  public brand!: number;

  @detailsField({ field: "brand", model: BrandBase })
  public brand_details?: BrandBase;

  @foreignKeyField({ related: "Line" })
  public line!: number;

  @detailsField({ field: "line", model: Line })
  public line_details?: Line;

  @imageField()
  public icon_details!: Image;

  @manyToManyField({ related: "Brand" })
  public categories!: number[];

  @detailsField({
    field: "categories",
    model: ProductCategory,
    many: true,
  })
  public categories_details!: ProductCategory[];

  @foreignKeyField({ related: "ProductNotation" })
  public notation!: number;

  @integerField()
  public pu!: number;

  @integerField()
  public pu100!: number;

  @charField()
  public network!: string;

  @charField()
  public paovalue!: string;

  @detailsField()
  public pao_details!: string[];

  @detailsField()
  public perem_details!: string[];

  @detailsField()
  public lot_details!: string[];

  @detailsField()
  public distribution_details!: string[];

  @datetimeField()
  public created!: Date;

  @datetimeField()
  public updated!: Date;

  @booleanField()
  public cobslabel!: boolean;

  @charField()
  public amazon!: string;

  @charField()
  public sales_link!: string;

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }

  public get amazon_link(): string | null {
    if (this.amazon && this.amazon.indexOf("http") === 0) {
      return this.amazon;
    }
    return null;
  }
}
