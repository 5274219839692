import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { SiteAdService } from "../site-ad.service";
import { AdZoneCampaignData } from "../site-ad";
import { filter, map, share } from "rxjs/operators";
import {
  AsyncPipe,
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
  NgClass,
  NgFor,
  NgIf,
} from "@angular/common";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { SiteTrackingService } from "../../stats/site-tracking.service";
import { weightedRandom } from "../../strings/random";

@Component({
  selector: "cobs-site-ad",
  templateUrl: "./site-ad.component.pug",
  styleUrls: ["./site-ad.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, NgFor, AsyncPipe],
})
export class SiteAdComponent implements OnInit {
  @Input() public mode: string = "topsite";
  public first = true;
  public visible = false;
  @ViewChild("ad", { static: true }) public ad?: ElementRef;
  public ad$!: Observable<AdZoneCampaignData>;

  constructor(
    private _ads: SiteAdService,
    private _trk: SiteTrackingService,
    protected _el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private lang: string,
    @Inject(PLATFORM_ID) private _platform: any
  ) {}

  public ngOnInit(): void {
    const vis$ = new ReplaySubject<boolean>();
    this.visible = isPlatformServer(this._platform);
    this.ad$ = combineLatest([this._ads.ads$, vis$]).pipe(
      // tap((a) => console.log("ads", this.mode, a)),
      map(([ad, _]) => ad),
      filter(() => this.visible && this.document.visibilityState !== "hidden"),
      map((ads) => ads[this.mode]),
      filter((a) => !!a),
      map((a) => {
        let ad: AdZoneCampaignData;
        if (this.first) {
          ad = a[0] as AdZoneCampaignData;
        } else {
          ad = weightedRandom(a) as AdZoneCampaignData;
        }
        if (isPlatformBrowser(this._platform)) {
          this._trk.track("ad:show", {
            ad: ad.campaign_id,
            adg: ad.group_id,
            adz: this.mode,
            expo: 1,
          });
        }
        this.first = false;
        return ad;
      }),
      share()
    );
    if (isPlatformBrowser(this._platform) && this.ad?.nativeElement) {
      const obs = new IntersectionObserver(
        (r) => {
          this.visible = r[0].isIntersecting;
          // console.log("visible", this.mode)
          vis$.next(this.visible);
        },
        { threshold: 0.8 }
      );
      obs.observe(this.ad?.nativeElement);
    } else {
      vis$.next(true);
    }
  }

  public storeClick(ad: AdZoneCampaignData) {
    const curSrc = this.ad?.nativeElement.querySelector("img").currentSrc;
    let mid: number = -1;
    if (curSrc) {
      for (const m of ad.medias) {
        if (m.srcset === curSrc) {
          mid = m.id;
        }
      }
    }
    this._trk.track("ad:click", {
      ad: ad.campaign_id,
      adg: ad.group_id,
      adz: this.mode,
      adm: mid,
    });
  }

  public getClasses(ad: AdZoneCampaignData): string {
    return this.mode;
  }
}
