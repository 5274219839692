import { DataModel } from "../data/data-model";

export interface AdZoneCampaignMediaData {
  id: number;
  src: string;
  minw: number;
  maxw: number;
  mode: string;
  width: number | null;
  ratio: number;
  height: number | null;
  srcset: string;
  media: string;
}

export interface AdZoneCampaignData {
  campaign_id: number;
  group_id: number;
  exposition: number;
  expiration: number;
  destination: string;
  title: string;
  medias: AdZoneCampaignMediaData[];
}

export interface AdZonesData {
  [index: string]: AdZoneCampaignData[];
}

export interface ISiteAdMediaImageMapArea {
  id: number;
  alt: string;
  title: string;
  destination: string;
  shape: string;
  coords: string;
  rcoords: string; // Coordonnées réelles
  rwidth: number; // Largeur réelle
}

export class SiteAd extends DataModel {
  static readonly _type: string = "SiteAd";
}
