import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { CURRENT_LOCALE_ID } from "../../i18n_setup";

export const httpi18nInterceptor = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  // Avoid request loop on /api/auth
  // SEE: add here a list of non-authenticated getUrl
  if (request.url.indexOf(environment.API_URL) === -1) {
    return next(request);
  }
  request = request.clone({
    setHeaders: {
      "Accept-Language": CURRENT_LOCALE_ID,
    },
  });
  return next(request);
};
