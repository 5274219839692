import { DataModel } from "../../common/data/data-model";
import {
  charField,
  detailsField,
  foreignKeyField,
  integerField,
  primaryField,
} from "../../common/data/fields";

export class ProductCategory extends DataModel {
  static readonly _type: string = "ProductCategory";

  @primaryField()
  public id!: number;

  @foreignKeyField({ related: "self" })
  public parent!: number;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @integerField()
  public level!: number;

  @detailsField({ many: false, model: ProductCategory })
  public parent_details!: ProductCategory;

  @detailsField({ many: true, model: ProductCategory })
  public parents_details!: ProductCategory[];

  public get fullName(): string {
    const out: string[] = [];
    if (this.parents_details) {
      for (const p of this.parents_details) {
        out.push(p.name);
      }
    }
    out.push(this.name);
    return out.join(" : ");
  }

  public get slugId(): string {
    const slugs: string[] = [];
    if (this.parents_details) {
      for (const p of this.parents_details) {
        slugs.push(p.slug);
      }
      slugs.push(this.slug);
      return `${slugs.join("-")}-${this.id}`;
    } else {
      return `${this.slug}-${this.id}`;
    }
  }
}
