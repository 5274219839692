import {HttpClient} from "@angular/common/http";
import {Inject, Injectable, LOCALE_ID, PLATFORM_ID} from "@angular/core";
import {environment} from "../../../environments/environment";
import {isPlatformBrowser} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {AuthService} from "../../access/services/auth.service";
import {DeviceIdService} from "./device-id.service";
import {SessionService} from "./session.service";
import {AutomationService} from "../automation/automation.service";
import {firstValueFrom} from "rxjs";

/**
 * Provides tracking calls for client. Listens to router url changes,
 * storing current url and previous urls, and authentication changes,
 * storing user token.
 *
 * Tracking sends data to _st api endpoint with
 *
 * - website version
 * - user token (for authenticated users)
 * - session id
 * - browser id
 * - referer url
 * - current url
 * - method
 */
@Injectable({
  providedIn: "root",
})
export class SiteTrackingService {
  private _authToken!: string;
  private _authUser!: number;
  private _aboUser!: string;

  constructor(
    private _http: HttpClient,
    private router: Router,
    private _auth: AuthService,
    private _deviceid: DeviceIdService,
    private _sess: SessionService,
    private _auts: AutomationService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platform: any
  ) {
    if (isPlatformBrowser(this.platform)) {
      this._previousUrl = document.referrer;
      this._currentUrl = document.referrer;
      router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          const ev = event as NavigationEnd;
          this._previousUrl = this._currentUrl;
          this._currentUrl = environment.MAIN_URL + "/" + locale + ev.url;
        });
      this._auth.current$().subscribe((cur) => {
        this._authToken = cur.token;
        this._authUser = cur.id;
        this._aboUser =
          cur.profile && cur.profile.join ? cur.profile.join(",") : "anon";
      });
    }
  }

  private _previousUrl!: string;

  /**
   * Returns previous route url.
   */
  public get previousUrl(): string {
    return this._previousUrl;
  }

  private _currentUrl!: string;

  /**
   * Returns current route url.
   */
  public get currentUrl(): string {
    return this._currentUrl;
  }

  /**
   * Send tracking data to _st endpoint
   *
   * @param action : action name
   * @param data : action data, updated with global tracking info
   */
  public async track(
    action: string = "",
    data: { [index: string]: any } = {}
  ): Promise<any> {
    if (isPlatformBrowser(this.platform)) {
      // Ensure auth data is available, eventually waiting for auth done
      await firstValueFrom(this._auth.current$());
      data.action = action;
      if (this._previousUrl && (!data.referrer || data.referrer === "auto")) {
        data.referrer = this._previousUrl;
      }
      if (this._currentUrl && (!data.url || data.url === "auto")) {
        data.url = this._currentUrl;
      }
      data.method = data.method ? data.method : "GET";
      data.version = environment.RELEASE + "-b" + environment.BUILD;
      data.session = this._sess.current;
      data.token = this._authToken;
      data.user = this._authUser;
      data.profile = this._aboUser;
      data.device = this._deviceid.current;
      try {
        const res: any = await firstValueFrom(
          this._http.post(`${environment.STATS_URL}/_st`, data, {
            headers: { contentType: "application/json" },
          })
        );
        return await this._auts.process(res);
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }
}
