import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";
import { Image } from "../../display/image";

export interface IImageFieldMetadata extends IFieldMetadata {
  chapo: boolean;
  field?: string;
}

export class ImageFieldManager
  extends BaseFieldManager
  implements IImageFieldMetadata
{
  public chapo = false;

  public fromJson(data: any): Image {
    return new Image(data);
  }
}

export function imageField(updates: IImageFieldMetadata = { chapo: false }) {
  return GenericDataField(updates, "imageField", ImageFieldManager);
}
