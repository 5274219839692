import { Ingredient } from "./ingredients/services/ingredient";
import { Brand } from "./products/services/brand";
import { Line } from "./products/services/line";
import { Product } from "./products/services/product";
import { Contract } from "./account/services/contract";
import { Order } from "./billing/services/order";
import { Rubric } from "./articles/services/rubric";
import { Article } from "./articles/services/article";
import { SalesCategory } from "./billing/services/sales-category";
import { SalesProduct } from "./billing/services/sales-product";
import { Career } from "./careers/services/career";
import {
  I18N_ROUTE_SEGMENTS,
  I18N_ROUTE_SEGMENTS_LANGS,
} from "./route_segments";
import { Expert } from "./site/services/expert";
import { Bill } from "./billing/services/bill";
import { CURRENT_LOCALE_ID, I18N_LANGUAGES } from "./i18n_setup";
import { ForumCategory } from "./community/services/forum";
import { ForumMessage } from "./community/services/message";
import { DirectMessage } from "./community/services/dmessage";
import { IngredientGroup } from "./ingredients/services/ingredientgroup";
import { ProductCategory } from "./products/services/category";
import { SalesService } from "./billing/services/sales-service";
import { Route } from "@angular/router";
import { ErrorViewComponent } from "./site/views/error-view/error-view.component";
import { RedirectorComponent } from "./site/components/redirector/redirector.component";

export type I18N_ROUTE_NAME =
  | "site_home"
  | "site_access_login"
  | "site_access_logout"
  | "site_access_forgottenpw"
  | "site_access_register"
  | "site_access_register_confirm"
  | "site_access_social_callback"
  | "site_ingredients"
  | "site_ingredients_detail"
  | "site_ingredients_slugid"
  | "site_ingredients_categories"
  | "site_ingredients_category"
  | "site_community"
  | "site_community_forum"
  | "site_community_forum_thread"
  | "site_community_forum_thread_byid"
  | "site_community_dms"
  | "site_community_dm_thread"
  | "site_brands"
  | "site_brands_list"
  | "site_brands_alpha"
  | "site_brands_detail"
  | "site_label_list"
  | "site_search"
  | "site_products"
  | "site_products_bybrand"
  | "site_products_byline"
  | "site_product_details"
  | "site_product_categories"
  | "site_product_category"
  | "site_account"
  | "site_account_profile"
  | "site_account_password"
  | "site_account_social"
  | "site_account_newsletters"
  | "site_account_usercontent"
  | "site_account_contracts"
  | "site_account_contracts_details"
  | "site_account_subscription"
  | "site_account_sponsorship"
  | "site_account_billinginfos"
  | "site_account_paymentsource"
  | "site_account_orders"
  | "site_account_orders_detail"
  | "site_account_bills"
  | "site_account_bills_detail"
  | "site_articles"
  | "site_articles_chrono"
  | "site_articles_rubric"
  | "site_articles_slugrubric"
  | "site_articles_article"
  | "site_articles_slugarticle"
  | "site_eshop_home"
  | "site_eshop_bycateg"
  | "site_eshop_product_details"
  | "site_eshop_ebook_details"
  | "site_eshop_service_details"
  | "site_eshop_bycateg_product_details"
  | "site_eshop_bycateg_ebook_details"
  | "site_eshop_bycateg_service_details"
  | "site_checkout"
  | "site_careers"
  | "site_careers_publish"
  | "site_careers_detail"
  | "site_briefs"
  | "site_experts"
  | "site_experts_detail"
  | "site_pressreview"
  | "site_subscriptions"
  | "site_subscriptions_details"
  | "site_subscriptions_subscribe"
  | "site_page"
  | "site_game"
  | "site_error"
  | "site_error_status";

export type I18N_ROUTE_PATH = string[];

export function i18nRouteBuilder(lang: string): Record<I18N_ROUTE_NAME, any> {
  if (lang === "all" || I18N_LANGUAGES.indexOf(lang) === -1) {
    console.error("Unknown language for routes", lang);
    lang = "fr";
  }
  const segments = I18N_ROUTE_SEGMENTS_LANGS[lang as "fr" | "en"];
  // tslint:disable:max-line-length
  return {
    site_home: ["/"],

    site_access_login: ["/", segments.access, segments.login],
    site_access_logout: ["/", segments.access, segments.logout],
    site_access_forgottenpw: ["/", segments.access, segments.forgottenpw],
    site_access_register: ["/", segments.access, segments.register],
    site_access_register_confirm: [
      "/",
      segments.access,
      segments.register,
      segments.confirm,
    ],
    site_access_social_callback: (backend: string) => [
      "/",
      segments.access,
      segments.callback,
      backend,
    ],

    site_ingredients: ["/", segments.ingredients],
    site_ingredients_detail: (ingredient: Ingredient) => [
      "/",
      segments.ingredients,
      ingredient.slugId,
    ],
    site_ingredients_slugid: (slug: string, id: number) => [
      "/",
      segments.ingredients,
      `${slug}-${id}`,
    ],
    site_ingredients_categories: [
      "/",
      segments.ingredients,
      segments.categories,
    ],
    site_ingredients_category: (category: IngredientGroup) => [
      "/",
      segments.ingredients,
      segments.categories,
      category.slugId,
    ],

    site_community: ["/", segments.community],
    site_community_forum: (forum: ForumCategory) => [
      "/",
      segments.community,
      segments.forums,
      forum.slugId,
    ],
    site_community_forum_thread: (
      forum: ForumCategory,
      thread: ForumMessage
    ) => [
      "/",
      segments.community,
      segments.forums,
      forum.slugId,
      thread.slugId,
    ],
    site_community_forum_thread_byid: (
      fs: string,
      fid: number,
      tslug: string,
      tid: number
    ) => [
      "/",
      segments.community,
      segments.forums,
      `${fs}-${fid}`,
      `${tslug}-${tid}`,
    ],
    site_community_dms: ["/", segments.community, segments.directmessages],
    site_community_dm_thread: (dm: DirectMessage) => [
      "/",
      segments.community,
      segments.directmessages,
      dm.slugId,
    ],

    site_brands: ["/", segments.brands],
    site_brands_list: ["/", segments.brands],
    site_brands_alpha: (letter: string) => [
      "/",
      segments.brands,
      segments.alpha,
      letter,
    ],
    site_brands_detail: (brand: Brand) => ["/", segments.brands, brand.slugId],

    site_search: ["/", segments.search],

    site_products: ["/", segments.products],
    site_label_list: ["/", segments.label],
    site_products_bybrand: (brand: Brand) => [
      "/",
      segments.products,
      brand.slugId,
    ],
    site_products_byline: (brand: Brand, line: Line) => [
      "/",
      segments.products,
      brand.slugId,
      line.slugId,
    ],
    site_product_details: (brand: Brand, line: Line, product: Product) => [
      "/",
      segments.products,
      brand.slugId,
      line.slugId,
      product.slugId,
    ],
    site_product_categories: ["/", segments.products, segments.categories],
    site_product_category: (category: ProductCategory) => [
      "/",
      segments.products,
      segments.categories,
      category.slugId ? category.slugId : `${category.slug}-${category.id}`,
    ],

    site_account: ["/", segments.account],
    site_account_profile: ["/", segments.account, segments.profile],
    site_account_password: ["/", segments.account, segments.password],
    site_account_social: ["/", segments.account, segments.social],
    site_account_newsletters: ["/", segments.account, segments.newsletters],
    site_account_usercontent: ["/", segments.account, segments.usercontent],
    site_account_contracts: ["/", segments.account, segments.contracts],
    site_account_contracts_details: (contract: Contract) => [
      "/",
      segments.account,
      segments.contracts,
      contract.id.toString(),
    ],
    site_account_subscription: ["/", segments.account, segments.subscription],
    site_account_sponsorship: ["/", segments.account, segments.sponsorship],
    site_account_billinginfos: ["/", segments.account, segments.billinginfos],
    site_account_paymentsource: [
      "/",
      segments.account,
      segments.paymentsources,
    ],
    site_account_orders: ["/", segments.account, segments.orders],
    site_account_orders_detail: (order: Order) => [
      "/",
      segments.account,
      segments.orders,
      order.id.toString(),
    ],
    site_account_bills: ["/", segments.account, segments.bills],
    site_account_bills_detail: (bill: Bill) => [
      "/",
      segments.account,
      segments.bills,
      bill.id.toString(),
    ],

    site_articles: ["/", segments.articles],
    site_articles_chrono: () => ["/", segments.articles, segments.chrono],
    site_articles_rubric: (rubric: Rubric) => [
      "/",
      segments.articles,
      rubric.slugId,
    ],
    site_articles_slugrubric: (slug: string) => ["/", segments.articles, slug],
    site_articles_article: (rubric: Rubric, article: Article) => [
      "/",
      segments.articles,
      rubric?.i18nSlugId(lang),
      article.i18nSlugId(lang),
    ],
    site_articles_slugarticle: (
      rslug: string,
      rid: number,
      slug: string,
      id: number
    ) => ["/", segments.articles, `${rslug}-${rid}`, `${slug}-${id}`],

    site_eshop_home: ["/", segments.eshop],
    site_eshop_bycateg: (categ: SalesCategory) => [
      "/",
      segments.eshop,
      segments.category,
      categ.i18nSlugId(lang),
    ],
    site_eshop_product_details: (pr: SalesProduct) => [
      "/",
      segments.eshop,
      segments.catalogproducts,
      pr.i18nSlugId(lang),
    ],
    site_eshop_ebook_details: (pr: SalesProduct) => [
      "/",
      segments.eshop,
      segments.catalogebooks,
      pr.i18nSlugId(lang),
    ],
    site_eshop_service_details: (pr: SalesService) => [
      "/",
      segments.eshop,
      segments.catalogservices,
      pr.i18nSlugId(lang),
    ],
    site_eshop_bycateg_product_details: (
      categ: SalesCategory,
      pr: SalesProduct
    ) => [
      "/",
      segments.eshop,
      segments.category,
      categ.i18nSlugId(lang),
      segments.catalogproducts,
      pr.i18nSlugId(lang),
    ],
    site_eshop_bycateg_ebook_details: (
      categ: SalesCategory,
      pr: SalesProduct
    ) => [
      "/",
      segments.eshop,
      segments.category,
      categ.i18nSlugId(lang),
      segments.catalogebooks,
      pr.i18nSlugId(lang),
    ],
    site_eshop_bycateg_service_details: (
      categ: SalesCategory,
      pr: SalesService
    ) => [
      "/",
      segments.eshop,
      segments.category,
      categ.i18nSlugId(lang),
      segments.catalogservices,
      pr.i18nSlugId(lang),
    ],

    site_careers: ["/", segments.careers],
    site_careers_publish: ["/", segments.careers, segments.publish],
    site_careers_detail: (career: Career) => [
      "/",
      segments.careers,
      career.slugId,
    ],

    site_experts: ["/", segments.experts],
    site_experts_detail: (e: Expert) => ["/", segments.experts, e.slugId],

    site_briefs: ["/", segments.briefs],

    site_pressreview: ["/", segments.pressreview],

    site_subscriptions: ["/", segments.subscriptions],
    site_subscriptions_details: (code: string) => [
      "/",
      segments.subscriptions,
      code,
    ],
    site_subscriptions_subscribe: (code: string) => [
      "/",
      segments.subscriptions,
      code,
      segments.subscribe,
    ],
    site_checkout: ["/", segments.checkout],
    site_page: (slug: string) => ["/", segments.page, slug],
    site_game: (slug: string) => ["/", segments.games, slug],
    site_error: ["/", segments.error],
    site_error_status: (status: string) => [
      "/",
      segments.error,
      status ? status : "404",
    ],
  };
}

// tslint:disable:max-line-length
export const I18N_ROUTES: Record<I18N_ROUTE_NAME, any> =
  i18nRouteBuilder(CURRENT_LOCALE_ID);

export const I18N_PAGES_SLUGS = {
  quisommesnous: $localize`:slug:qui-sommes-nous`,
  mentionsLegales: $localize`:slug:mentions-legales`,
  contact: $localize`:slug:contact`,
  faq: $localize`:slug:faq`,
};

export const APP_ROUTES: Route[] = [
  {
    path: "",
    data: {
      title: $localize`:breadcrumb:Accueil`,
      icon: "fa fa-home",
    },
    children: [
      // REDIRECTS FROM V2
      {
        path:
          I18N_ROUTE_SEGMENTS.eshop +
          "/" +
          I18N_ROUTE_SEGMENTS.ebooks +
          "/:ebook",
        component: RedirectorComponent,
        data: { skip: true, redirect: "ebookDetail" },
      },
      {
        path:
          I18N_ROUTE_SEGMENTS.eshop +
          "/" +
          I18N_ROUTE_SEGMENTS.subscriptions +
          "/:subscription",
        component: RedirectorComponent,
        data: { skip: true, redirect: "subscriptionDetail" },
      },
      {
        path: I18N_ROUTE_SEGMENTS.eshop + "/" + I18N_ROUTE_SEGMENTS.ebooks,
        component: RedirectorComponent,
        data: { skip: true, redirect: "ebookList" },
      },
      {
        path:
          I18N_ROUTE_SEGMENTS.eshop + "/" + I18N_ROUTE_SEGMENTS.subscriptions,
        component: RedirectorComponent,
        data: { skip: true, redirect: "subscriptionList" },
      },
      {
        path: I18N_ROUTE_SEGMENTS.ingredient + "/:ingredient",
        component: RedirectorComponent,
        data: { skip: true, redirect: "ingredientDetail" },
      },
      {
        path: I18N_ROUTE_SEGMENTS.ingredient,
        component: RedirectorComponent,
        data: { skip: true, redirect: "ingredientList" },
      },
      {
        path: I18N_ROUTE_SEGMENTS.ebooks,
        component: RedirectorComponent,
        data: { skip: true, redirect: "ebookList" },
      },
      {
        path: I18N_ROUTE_SEGMENTS.ebooks + "/:ebook",
        component: RedirectorComponent,
        data: { skip: true, redirect: "ebookDetail" },
      },

      // ROUTES
      {
        path: "",
        pathMatch: "full",
        loadChildren: () =>
          import("./routing/home-views-routes").then((mod) => mod.HOME_ROUTES),
        data: {
          preload: false,
          skip: true,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.uhome,
        loadChildren: () =>
          import("./routing/home-views-routes").then((mod) => mod.HOME_ROUTES),
        data: {
          preload: false,
          skip: true,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.access,
        loadChildren: () =>
          import("./routing/access-views-routing").then(
            (mod) => mod.AUTHENTICATION_ROUTES
          ),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Identification`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.ingredients,
        loadChildren: () =>
          import("./routing/ingredients-routing").then(
            (mod) => mod.INGREDIENTS_ROUTES
          ),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Ingrédients`,
        },
      },

      {
        path: I18N_ROUTE_SEGMENTS.brands,
        loadChildren: () =>
          import("./routing/brands-routing").then((mod) => mod.BRANDS_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Marques`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.label,
        loadChildren: () =>
          import("./routing/label-routing").then((mod) => mod.LABEL_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Label CosmeticObs`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.products,
        loadChildren: () =>
          import("./routing/products-routing").then(
            (mod) => mod.PRODUCTS_ROUTES
          ),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Produits`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.account,
        loadChildren: () =>
          import("./routing/account-routing").then((mod) => mod.ACCOUNT_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Mon compte`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.articles,
        loadChildren: () =>
          import("./routing/articles-routing").then(
            (mod) => mod.ARTICLES_ROUTES
          ),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Articles`,
        },
      },

      {
        path: I18N_ROUTE_SEGMENTS.careers,
        loadChildren: () =>
          import("./routing/careers-routing").then((mod) => mod.CAREERS_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Carrières`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.briefs,
        loadChildren: () =>
          import("./routing/feeds-routing").then((mod) => mod.FEEDS_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Brèves`,
          variant: "briefs",
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.pressreview,
        loadChildren: () =>
          import("./routing/feeds-routing").then((mod) => mod.FEEDS_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Revue de presse`,
          variant: "pressreview",
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.search,
        loadChildren: () =>
          import("./routing/search-routing").then((mod) => mod.SEARCH_ROUTES),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Recherche`,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.checkout,
        loadChildren: () =>
          import("./routing/billing-routing").then((mod) => mod.BILLING_ROUTES),
        data: {
          preload: true,
          skip: true,
        },
      },
      {
        path: I18N_ROUTE_SEGMENTS.community,
        loadChildren: () =>
          import("./routing/community-routing").then(
            (mod) => mod.COMMUNITY_ROUTES
          ),
        data: {
          preload: true,
          title: $localize`:breadcrumb:Forums pro`,
        },
      },
      // {
      //   path: I18N_ROUTE_SEGMENTS.games,
      //   loadChildren: () =>
      //     import("./routing/games-routing").then((mod) => mod.GAMES_ROUTES),
      //   data: {
      //     preload: false,
      //     title: $localize`:breadcrumb:Jeux`,
      //   },
      // },

      {
        path: I18N_ROUTE_SEGMENTS.eshop,
        loadChildren: () =>
          import("./routing/eshop-routing").then((mod) => mod.ESHOP_ROUTES),
        data: {
          preload: false,
          title: $localize`:breadcrumb:Boutique`,
        },
      },

      {
        path: "",
        loadChildren: () =>
          import("./routing/site-views-routing").then((mod) => mod.SITE_ROUTES),
        data: {
          preload: false,
          skip: true,
        },
      },
    ],
  },
  {
    path: I18N_ROUTE_SEGMENTS.error + "/:errCode",
    component: ErrorViewComponent,
    data: {
      title: $localize`:breadcrumb:Erreur`,
    },
  },
  {
    path: I18N_ROUTE_SEGMENTS.error,
    component: ErrorViewComponent,
    data: {
      title: $localize`:breadcrumb:Erreur`,
      errCode: "xxx",
    },
  },
  {
    path: "**",
    component: ErrorViewComponent,
    data: {
      title: $localize`:breadcrumb:Erreur`,
      errCode: "404",
    },
  },
];
