import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from "@angular/core";

import { isPlatformBrowser } from "@angular/common";
import { WindowRefService } from "../display/window-ref.service";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    @Inject(PLATFORM_ID) public platform: any,
    @Inject(LOCALE_ID) private _lang: string,
    private _router: Router,
    private windowRef: WindowRefService
  ) {}

  public get ga(): Function {
    if (!isPlatformBrowser(this.platform)) {
      return () => {};
    } else {
      return (this.windowRef.nativeWindow as any).gtag || (() => {});
    }
  }

  public trackPages() {
    if (isPlatformBrowser(this.platform)) {
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          try {
            this.ga("config", "G-0YX4NTND5X", {
              page_path: "/" + this._lang + event.urlAfterRedirects,
            });
          } catch (e) {}
        }
      });
    } else {
      console.log("Pageview");
    }
  }
}
