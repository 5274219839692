import {DataBackend} from "../../common/data/data-backend";
import {Collection} from "../../common/data/data-collection";
import {Injectable} from "@angular/core";
import {Rubric} from "./rubric";
import {Observable, ReplaySubject} from "rxjs";
import {first, map} from "rxjs/operators";

// TODO: remove this after 2022-01-01
const RUBRIC_REDIRECT_MAPPING: { [index: number]: number } = {
  7: 34,
  25: 54,
  18: 57,
};

@Injectable({
  providedIn: "root",
})
export class RubricService extends Collection<Rubric> {
  private _all!: ReplaySubject<Rubric[]>;

  constructor(_backend: DataBackend) {
    super(_backend, "/api/rubrics", Rubric);
  }

  public get all$(): Observable<Rubric[]> {
    if (this._all === undefined) {
      this._all = new ReplaySubject<Rubric[]>(1);
      this.list({ page_size: "100" })
        .pipe(first())
        .subscribe({
          next: (res) => {
            const rubs: Rubric[] = [];
            for (const r of res.results) {
              const ro = new Rubric(this);
              ro.fromJson(r);
              rubs.push(ro);
            }
            this._all.next(rubs);
          },
          error: (err) => {
            this._all.next([]);
          },
        });
    }
    return this._all.asObservable();
  }

  public byId(id: number): Observable<Rubric> {
    return this.all$.pipe(
      first(),
      map((rubs) => {
        let redirect = false;
        if (RUBRIC_REDIRECT_MAPPING[id] !== undefined) {
          id = RUBRIC_REDIRECT_MAPPING[id];
          redirect = true;
        }
        for (const r of rubs) {
          if (r.id === id) {
            r.redirect = redirect;
            return r;
          }
        }
        throw Error($localize`Rubrique inconnue`);
      })
    );
  }
}
