import { BaseFieldManager, GenericDataField, IFieldMetadata } from "./base";

export interface IForeignKeyFieldMetadata extends IFieldMetadata {
  related: string;
}

export interface IReverseForeignKeyFieldMetadata extends IFieldMetadata {
  related: string;
}

export interface IDetailsFieldMetadata extends IFieldMetadata {
  field?: string;
  many?: boolean;
  model?: any;
  deserialize?: (data: any) => any;
}

export interface IManyToManyFieldMetadata extends IFieldMetadata {
  related: string;
}

export class ForeignKeyFieldManager
  extends BaseFieldManager
  implements IForeignKeyFieldMetadata
{
  public related!: string;
}

export class ReverseForeignKeyFieldManager
  extends BaseFieldManager
  implements IForeignKeyFieldMetadata
{
  public related!: string;
}

export class DetailsFieldManager
  extends BaseFieldManager
  implements IDetailsFieldMetadata
{
  public field?: string;
  public many?: boolean;
  public model?: any;
  public deserialize?: (data: any) => any;

  public fromJson(data: any): any {
    if (data === null) {
      return data;
    }
    if (data === undefined) {
      return undefined;
    }
    if (this.deserialize) {
      return this.deserialize(data);
    }
    if (this.model) {
      if (!this.many) {
        const d = new this.model();
        d.fromJson(data);
        return d;
      } else {
        const out: any[] = [];
        for (const dt of data) {
          const d = new this.model();
          d.fromJson(dt);
          out.push(d);
        }
        return out;
      }
    }
    return data;
  }
}

export class ManyToManyFieldManager
  extends BaseFieldManager
  implements IForeignKeyFieldMetadata
{
  public related!: string;
}

export function foreignKeyField(updates: IForeignKeyFieldMetadata) {
  return GenericDataField(updates, "foreignKeyField", ForeignKeyFieldManager);
}

export function reverseForeignKeyField(
  updates: IReverseForeignKeyFieldMetadata
) {
  return GenericDataField(
    updates,
    "reverseForeignKeyField",
    ReverseForeignKeyFieldManager
  );
}

export function detailsField(updates: IDetailsFieldMetadata = {}) {
  return GenericDataField(updates, "detailsField", DetailsFieldManager);
}

export function manyToManyField(updates: IManyToManyFieldMetadata) {
  return GenericDataField(updates, "manyToManyField", ManyToManyFieldManager);
}
