import {DISPLAY_SIZES} from "./sizesmodes";
import {environment} from "../../../environments/environment";

/**
 * Image from api class.
 *
 * Manage responsive behaviour for image.
 */
export class Image {
  /** Image source */
  src!: string;
  /** Image title */
  title!: string;
  /** Image credits */
  credits?: string;

  /**
   * Image language
   */
  lang: string | null = null;

  /** Image mode */
  mode: string | null = null;

  /**
   * Constructor for image, fields are populated from data.
   *
   * - data.src or data.media => src
   * - data.title or data.legend => title
   * - data.credits => credits
   *
   * TODO: add dumb image if no src or no media found (inline grey png ??)
   *
   * @param data image data
   */
  constructor(data: any) {
    if (!data) {
      return;
    }
    if (data.src) {
      this.src = data.src;
    } else if (data.media) {
      this.src = data.media;
    }

    if (data.title) {
      this.title = data.title;
    } else if (data.legend) {
      this.title = data.legend;
    }

    if (data.credits) {
      this.credits = data.credits;
    }

    if (data.lang) {
      this.lang = data.lang;
    }

    if (data.icon) {
      this.mode = "icon";
    }
  }

  /**
   * Image base url.
   */
  public get url(): string {
    return this.src;
  }

  /**
   * Return image variant with options.
   *
   * On production, just use cloudfront origin failover.
   *
   * On staging & dev, replace media base url from `environment.MEDIA_BASE`
   * with `environment.RESPONSIVE_MEDIA_BASE`
   *
   * Images ending with "-anim.gif" are not resized / transformed.
   *
   * @param size image size (from DISPLAY_SIZES), or empty string for no resizing
   * @param options image options (for now : noborder, check reusaize project)
   * @param extension image extension (default : webp)
   */
  public rurl(
    size: DISPLAY_SIZES | "" = "md",
    options: string[] = ["noborder"],
    extension: string = ".webp"
  ): string {
    if (!environment.USE_RESPONSIVE_MEDIAS) {
      return this.url;
    }
    if (!this.src) {
      return this.url;
    }
    // Don't reduce animated gifs..
    if (this.src.indexOf("-anim.gif") !== -1) {
      return this.src;
    }
    const extensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".JPG",
      ".JPEG",
      ".PNG",
      ".gif",
      ".GIF",
    ];
    for (const e of extensions) {
      if (this.src.indexOf(e) === this.src.length - e.length) {
        try {
          let base = this.url;
          if (
            environment.MEDIA_BASE.length > 0 &&
            environment.RESPONSIVE_MEDIA_BASE.length > 0
          ) {
            base = base.replace(
              environment.MEDIA_BASE,
              environment.RESPONSIVE_MEDIA_BASE
            );
          }
          base = base.replace(e, "");
          base += "--";
          if (size !== "") {
            base += [size as any].concat(options).join("-");
          }
          base += extension;
          return base;
        } catch (e) {
          console.error("Error setting reusaize", e);
        }
      }
    }
    return this.url;
  }
}
