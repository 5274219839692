import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, of, shareReplay, timer } from "rxjs";
import { DataBackend } from "../data/data-backend";
import { Collection } from "../data/data-collection";
import { AdZonesData, SiteAd } from "./site-ad";
import { switchMap, tap } from "rxjs/operators";
import { isPlatformServer } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SiteAdService extends Collection<SiteAd> {
  public ads: AdZonesData = {};
  public _ads!: Observable<AdZonesData>;

  constructor(
    _backend: DataBackend,
    @Inject(PLATFORM_ID) private _platform: any
  ) {
    super(_backend, "/api/siteadscampaigns", SiteAd);
  }

  public get ads$(): Observable<AdZonesData> {
    if (!this._ads) {
      const src = isPlatformServer(this._platform) ? of(0) : timer(0, 20000);
      this._ads = src.pipe(
        switchMap((c) => {
          if (c % 20 === 0) {
            return this.action(null, "GET", "cur", {});
          } else {
            return of(this.ads);
          }
        }),
        tap((d) => (this.ads = d)),
        // tap(() => console.log("Ad$ tick")),
        shareReplay()
      );
    }
    return this._ads;
  }
}
