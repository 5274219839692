import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LazyComponentService {
  private _loaded: string[] = [];

  public loaded(comp: string) {
    if (this._loaded.indexOf(comp) === -1) {
      this._loaded.push(comp);
    }
  }

  public isLoaded(comp: string): boolean {
    return this._loaded.indexOf(comp) !== -1;
  }
}
