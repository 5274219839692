import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {StorageService} from "../storage/storage.service";
import {isPlatformBrowser} from "@angular/common";
import {getCheckableRandomString} from "../strings/random";
import {CookiesService} from "../cookies/cookies.service";
import {environment} from "../../../environments/environment";

/**
 * Provides a session identifier service, stored in cookies and localstorage.
 * Sessions are renewed as soon as user logs out.
 *
 * Session duration is 10 years by default.
 */
@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(
    private _stor: StorageService,
    private _cook: CookiesService,
    @Inject(PLATFORM_ID) private _platform: any
  ) {}

  /**
   * Get or create a session id.
   */
  public get current(): string | null {
    if (!isPlatformBrowser(this._platform)) {
      return "";
    }
    if (this._stor.getRawItem("session_id") === null) {
      const sid = getCheckableRandomString(20);
      this._stor.setRawItem("session_id", sid);
      const exp = new Date();
      exp.setDate(exp.getDate() + 3650);
      this._cook.put("session_id", sid, {
        path: "/",
        expires: exp,
        secure: environment.production,
        httpOnly: false,
        sameSite: "Strict",
      });
    }
    return this._stor.getRawItem("session_id");
  }

  public renew(): string | null {
    if (!isPlatformBrowser(this._platform)) {
      return "";
    }
    this._stor.removeItem("session_id");
    this._cook.remove("session_id");
    return this.current;
  }
}
