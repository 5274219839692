import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Auth, AuthService } from "../../../../access/services/auth.service";
import { I18N_ROUTES } from "../../../../app-routes";
import { NavigationStart, Router, RouterLink } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import {
  NgbDropdown,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { LazyComponentComponent } from "../../../../common/display/lazyness/lazy-component/lazy-component.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: "cobs-header-userinfos",
  templateUrl: "./header-userinfos.component.pug",
  styleUrls: ["./header-userinfos.component.sass"],
  standalone: true,
  imports: [
    NgIf,
    NgbDropdown,
    NgClass,
    NgbDropdownToggle,
    NgbDropdownMenu,
    RouterLink,
    LazyComponentComponent,
  ],
})
export class HeaderUserinfosComponent implements OnInit, OnDestroy {
  @Input() public mode: "full" | "mobile" = "full";
  @Output() public clicked = new EventEmitter<undefined | "open" | "close">();
  @ViewChild("drop1", { static: false }) public drop1?: NgbDropdown;
  @ViewChild("drop2", { static: false }) public drop2?: NgbDropdown;

  public auth!: Auth;
  public I18N_ROUTES = I18N_ROUTES;
  private _subscriptions = new Subject<void>();

  constructor(private _auth: AuthService, private _router: Router) {}

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  ngOnInit() {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.clicked.emit("close");
        if (this.drop1) {
          this.drop1.close();
        }
        if (this.drop2) {
          this.drop2.close();
        }
      });
    this._auth
      .current$()
      .pipe(takeUntil(this._subscriptions))
      .subscribe((auth) => {
        this.auth = auth;
      });
  }
}
