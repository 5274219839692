import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { MessageService } from "../../site/services/message.service";

export const http403ResponseInterceptor = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const auth = inject(AuthService);
  const msgs = inject(MessageService);
  return next(req).pipe(
    catchError((evt) => {
      if (evt instanceof HttpErrorResponse) {
        if (
          evt.status === 403 &&
          req.url.indexOf("/api/auth") === -1 &&
          req.url.indexOf("/api/social/connect") === -1
        ) {
          return auth.autologin().pipe(
            switchMap((res) => {
              if (!res.success && res.message === "Unknown or invalid token") {
                msgs.danger(
                  $localize`Vous avez été déconnecté de votre compte`
                );
              }
              throw evt;
            }),
            catchError((err) => {
              throw evt;
            })
          );
        }
      }
      throw evt;
    })
  );
};
