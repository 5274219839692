
<h1 class="text-center mt-5 text-danger" *ngIf="status=='xxx'"><i class="icon-question-circle-o icon-10x"></i><br>
  <ng-container i18n>Erreur inconnue</ng-container>
</h1>
<h1 class="text-center mt-5 text-danger" *ngIf="status=='403'"><i class="icon-attention icon-10x"></i><br>
  <ng-container i18n>Acces interdit</ng-container>
</h1>
<h1 class="text-center mt-5 text-danger" *ngIf="status=='404'"><i class="icon-trash icon-10x"></i><br>
  <ng-container i18n>Page non trouvée</ng-container>
</h1>
<h1 class="text-center mt-5 text-danger" *ngIf="status=='500' || status == '502'"><i class="icon-bomb icon-10x"></i><br>
  <ng-container i18n>Erreur du serveur</ng-container>
</h1>
<h1 class="text-center mt-5 text-danger" *ngIf="status=='429'"><i class="icon-gauge icon-10x"></i><br>
  <ng-container i18n>Trop de requêtes</ng-container>
</h1>
<h1 class="text-center mt-5 text-danger" *ngIf="status=='0'"><i class="icon-wifi icon-10x"></i><br>
  <ng-container i18n>Problème de réseau</ng-container>
</h1>