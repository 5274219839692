import { Component, OnInit } from "@angular/core";
import { ServerResponseService } from "../../../common/errors/server-response.service";
import { ActivatedRoute } from "@angular/router";
import { NgIf } from "@angular/common";

const KNOWN_STATUS_ERRORS = ["0", "403", "404", "500", "502", "429", "xxx"];

@Component({
  selector: "cobs-error-view",
  templateUrl: "./error-view.component.pug",
  styleUrls: ["./error-view.component.sass"],
  standalone: true,
  imports: [NgIf],
})
export class ErrorViewComponent implements OnInit {
  public status!: string;

  constructor(
    private serverResponse: ServerResponseService,
    private route: ActivatedRoute
  ) {
    this.serverResponse.setNotFound().setCacheNone();
  }

  ngOnInit() {
    this.status =
      this.route.snapshot.params.errCode ||
      this.route.snapshot.data.errCode ||
      "xxx";
    if (KNOWN_STATUS_ERRORS.indexOf(this.status) === -1) {
      this.status = "xxx";
    }
  }
}
