import { CURRENT_LOCALE_ID } from "./i18n_setup";

export const I18N_ROUTE_SEGMENTS_LANGS = {
  fr: {
    home: "",
    uhome: "accueil",
    access: "acces",
    login: "connexion",
    logout: "deconnexion",
    forgottenpw: "mot-de-passe-oublie",
    register: "inscription",
    confirm: "confirmation",
    callback: "callback",
    ingredients: "ingredients",
    ingredient: "ingredient-cosmetique",
    directmessages: "dms",
    search: "recherche",

    community: "communaute",
    forums: "forums",
    messages: "messages",

    brands: "marques",
    label: "label",
    alpha: "alpha",

    products: "produits",
    experts: "experts",
    account: "mon-compte",
    profile: "profil",
    password: "mot-de-passe",
    social: "social",
    newsletters: "newsletters",
    usercontent: "usercontent",
    subscription: "abonnement",
    sponsorship: "sponsoring",
    billinginfos: "adresses-facturation",
    paymentsources: "sources-de-paiement",
    orders: "commandes",
    bills: "factures",
    contracts: "contrats",

    articles: "articles",
    category: "categorie",
    categories: "categories",
    chrono: "chronologique",
    eshop: "boutique",
    ebooks: "ebooks",
    catalogproducts: "p",
    catalogservices: "s",
    catalogebooks: "e",
    careers: "carrieres",
    publish: "publier",

    briefs: "breves",

    pressreview: "revue-de-presse",

    subscriptions: "abonnements",
    subscribe: "m-abonner",
    checkout: "paiement",
    page: "page",
    games: "jeux",
    error: "error",
  },
  en: {
    home: "",
    uhome: "home",
    access: "access",
    login: "login",
    logout: "logout",
    forgottenpw: "forgottenpw",
    register: "register",
    confirm: "confirm",
    callback: "callback",
    ingredients: "ingredients",
    ingredient: "cosmetic-ingredient",

    community: "community",
    forums: "forums",
    messages: "messages",
    directmessages: "dms",

    brands: "brands",
    label: "label",
    alpha: "alpha",

    search: "search",

    products: "products",
    experts: "experts",

    account: "account",
    profile: "profile",
    password: "password",
    social: "social",
    newsletters: "newsletters",
    usercontent: "usercontent",
    subscription: "subscription",
    sponsorship: "sponsorship",
    billinginfos: "billinginfos",
    paymentsources: "paymentsources",
    orders: "orders",
    bills: "bills",
    contracts: "contracts",

    articles: "articles",
    category: "category",
    chrono: "chronological",

    categories: "categories",

    eshop: "eshop",
    ebooks: "ebooks",
    catalogproducts: "p",
    catalogservices: "s",
    catalogebooks: "e",

    careers: "careers",
    publish: "publish",

    briefs: "briefs",

    pressreview: "pressreview",

    subscriptions: "subscriptions",
    subscribe: "subscribe",
    checkout: "checkout",
    games: "games",
    page: "page",
    error: "error",
  },
  es: {
    home: "",
    uhome: "home",
    access: "access",
    login: "login",
    logout: "logout",
    forgottenpw: "forgottenpw",
    register: "register",
    confirm: "confirm",
    callback: "callback",
    ingredients: "ingredients",
    ingredient: "ingredient",

    community: "community",
    forums: "forums",
    messages: "messages",
    directmessages: "dms",

    brands: "brands",
    label: "label",
    alpha: "alpha",

    search: "buscar",

    products: "products",
    experts: "experts",

    account: "account",
    profile: "profile",
    password: "password",
    social: "social",
    newsletters: "newsletters",
    usercontent: "usercontent",
    subscription: "subscription",
    sponsorship: "sponsorship",
    billinginfos: "billinginfos",
    paymentsources: "paymentsources",
    orders: "orders",
    bills: "bills",
    contracts: "contracts",

    articles: "articles",
    category: "categoria",
    chrono: "chrono",

    categories: "categorias",

    eshop: "tienda",
    ebooks: "ebooks",
    catalogproducts: "p",
    catalogservices: "s",
    catalogebooks: "e",

    careers: "careers",
    publish: "publish",

    briefs: "briefs",

    pressreview: "pressreview",

    subscriptions: "subscriptions",
    subscribe: "subscribe",
    checkout: "checkout",
    page: "page",
    games: "games",
    error: "error",
  },
  de: {
    home: "",
    uhome: "home",
    access: "access",
    login: "login",
    logout: "logout",
    forgottenpw: "forgottenpw",
    register: "register",
    confirm: "confirm",
    callback: "callback",
    ingredients: "ingredients",
    ingredient: "ingredient",

    community: "community",
    forums: "forums",
    messages: "messages",
    directmessages: "dms",

    brands: "brands",
    label: "label",
    alpha: "alpha",

    search: "search",

    products: "products",
    experts: "experts",

    account: "account",
    profile: "profile",
    password: "password",
    social: "social",
    newsletters: "newsletters",
    usercontent: "usercontent",
    subscription: "subscription",
    sponsorship: "sponsorship",
    billinginfos: "billinginfos",
    paymentsources: "paymentsources",
    orders: "orders",
    bills: "bills",
    contracts: "contracts",

    articles: "articles",
    category: "category",
    chrono: "chrono",
    categories: "categories",

    eshop: "shop",
    ebooks: "ebooks",
    catalogproducts: "p",
    catalogservices: "s",
    catalogebooks: "e",

    careers: "careers",
    publish: "publish",

    briefs: "briefs",

    pressreview: "pressreview",

    subscriptions: "subscriptions",
    subscribe: "subscribe",
    checkout: "checkout",
    page: "page",
    games: "games",
    error: "error",
  },
};

export const I18N_ROUTE_SEGMENTS =
  I18N_ROUTE_SEGMENTS_LANGS[CURRENT_LOCALE_ID as "fr" | "en"];
