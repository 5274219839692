import { Injectable } from "@angular/core";
import { detailsField } from "../../common/data/fields";
import { Content } from "../../common/content/services/content";
import { Article } from "./article";
import { contentField } from "../../common/data/fields/content";
import { RubricBase } from "./rubric.base";

@Injectable({
  providedIn: "root",
})
export class Rubric extends RubricBase {
  static readonly _type: string = "Rubric";

  @contentField({ chapo: true })
  public chapo_details!: Content;

  @contentField()
  public content_details!: Content;

  @detailsField()
  public i18n_slugs!: { [index: string]: string };

  public redirect: boolean = false;

  @detailsField({
    deserialize: (data) => {
      const out = { headlines: [] as Article[] };
      if (data.headlines) {
        for (const u of data.headlines) {
          const a = new Article();
          a.fromJson(u);
          out.headlines.push(a);
        }
      }
      return out;
    },
  })
  public headlines_details?: { headlines: Article[] };
}
