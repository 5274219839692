import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { CURRENT_LOCALE_ID, I18N_ENABLED_LANGUAGES } from "../../i18n_setup";
import { HeadService } from "./head.service";
import { IQueryMetaNav } from "../data/data-query";
import { HttpParams } from "@angular/common/http";

export interface ISeo {
  title: string;
  description: string;
  keywords: string;
  author: string;
  twitter_author: string;
  copyright: string;
  canonical: string;
  alternate: { [index: string]: string };
  next: string;
  prev: string;
  ogtitle: string;
  ogtype: string;
  ogdescription: string;
  ogimage: string;
  ogurl: string;
}

export interface ICanonicalOptions {
  params?: { [index: string]: string };
  nav?: IQueryMetaNav;
  hash?: string;
}

@Injectable({
  providedIn: "root",
})
export class SeoService {
  private _data$ = new ReplaySubject<any>(1);
  private current: ISeo = {
    title: "",
    description: "",
    keywords: "",
    author: "",
    twitter_author: "@CosmeticOBS",
    copyright: "",
    canonical: "",
    alternate: {},
    next: "",
    prev: "",
    ogtitle: "",
    ogtype: "",
    ogdescription: "",
    ogimage: "",
    ogurl: "",
  };

  constructor(
    private _title: Title,
    private _meta: Meta,
    private _head: HeadService
  ) {}

  public get data(): Observable<any> {
    return this._data$.asObservable();
  }

  public get currentUrl(): string | null {
    try {
      return this._meta.getTags("name=canonical")[0].getAttribute("href");
    } catch (e) {
      return null;
    }
  }

  public setTitle(...title: string[]): void {
    this.current.title = (title as string[]).join(" - ");
    this._title.setTitle(this.current.title);
    this._data$.next(this.current);
  }

  public setDescription(...descriptionList: string[]): void {
    this.current.description = descriptionList.join(", ");
    if (this._meta.getTag("name=description")) {
      this._meta.updateTag(
        { name: "description", content: this.current.description },
        "name=description"
      );
    } else {
      this._meta.addTag({
        name: "description",
        content: this.current.description,
      });
    }
    this._data$.next(this.current);
  }

  public setKeywords(...keywordList: string[]): void {
    this.current.keywords = keywordList.join(", ");
    if (this._meta.getTag("name=keywords")) {
      this._meta.updateTag(
        { name: "keywords", content: this.current.keywords },
        "name=keywords"
      );
    } else {
      this._meta.addTag({ name: "keywords", content: this.current.keywords });
    }
    this._data$.next(this.current);
  }

  public setAuthor(
    author: string = "CosmeticOBS - Observatoire des Cosmétiques"
  ): void {
    let twAuthor = "@CosmeticOBS";
    if (author === "LW") {
      author = "Laurence WITTNER";
    } else if (author === "JS") {
      author = "Jasmine SALMI";
    }
    this.current.author = author;
    this.current.twitter_author = twAuthor;
    if (this._meta.getTag("name=author")) {
      this._meta.updateTag({ name: "author", content: author }, "name=author");
    } else {
      this._meta.addTag({ name: "author", content: author });
    }
    if (this._meta.getTag("name='twitter:site'")) {
      this._meta.updateTag(
        { name: "twitter:site", content: twAuthor },
        "name='twitter:site'"
      );
    } else {
      this._meta.addTag({ name: "twitter:site", content: twAuthor });
    }
    this._data$.next(this.current);
  }

  public setCopyright(
    copyright: string = "(c) CosmeticOBS - Observatoire des Cosmétiques"
  ): void {
    if (this._meta.getTag("name=copyright")) {
      this._meta.updateTag(
        { name: "copyright", content: copyright },
        "name=copyright"
      );
    } else {
      this._meta.addTag({ name: "copyright", content: copyright });
    }
    this.current.copyright = copyright;
    this._data$.next(this.current);
  }

  public setCanonical(
    canonical: string | string[],
    options: ICanonicalOptions = {}
  ) {
    let url: string | null = null;
    let next: string | null = null;
    let nextParams: HttpParams | null = null;
    let prev: string | null = null;
    let prevParams: HttpParams | null = null;
    if (typeof canonical === "string") {
      url = canonical;
    } else {
      url = (canonical as string[]).join("/").replace("//", "/");
    }
    if (url.indexOf("http") === -1) {
      url = environment.MAIN_URL + "/" + CURRENT_LOCALE_ID + url;
    }
    if (options.hash) {
      url += "#" + options.hash;
    }
    let params = new HttpParams(
      options.params ? { fromObject: options.params } : {}
    );
    if (options.nav) {
      if (options.nav.page && options.nav.page > 1) {
        params = params.set("page", options.nav.page.toString(10));
      }
      if (options.nav.next) {
        next = url;
        if (options.nav.next !== 1) {
          nextParams = params.set("page", options.nav.next.toString(10));
        } else {
          nextParams = params.delete("page");
        }
      }
      if (options.nav.prev) {
        prev = url;
        if (options.nav.prev !== 1) {
          prevParams = params.set("page", options.nav.prev.toString(10));
        } else {
          prevParams = params.delete("page");
        }
      }
    }
    if (params.keys().length > 0) {
      url += "?" + params.toString();
    }
    if (next && nextParams) {
      if (nextParams.keys().length > 0) {
        next += "?" + nextParams.toString();
      }
      this.current.next = next;
    } else {
      this.current.next = "";
    }
    if (prev && prevParams) {
      if (prevParams.keys().length > 0) {
        prev += "?" + prevParams.toString();
      }
      this.current.prev = prev;
    } else {
      this.current.prev = "";
    }

    this.current.canonical = url;
    this._head.setCanonical(url, prev, next);
    this._head.setHtmlLanguage(CURRENT_LOCALE_ID);
    this._data$.next(this.current);
  }

  public setI18nAlternative(
    lang: string,
    href: string | string[],
    options: ICanonicalOptions = {}
  ) {
    if (I18N_ENABLED_LANGUAGES.indexOf(lang) === -1) {
      return;
    }
    let url: string;
    if (typeof href !== "string") {
      url = (href as string[]).join("/").replace("//", "/");
    } else {
      url = href;
    }
    if (url.indexOf("http") === -1) {
      url = environment.MAIN_URL + "/" + lang + url;
    }
    if (options.hash) {
      url += "#" + options.hash;
    }
    let params = new HttpParams(
      options.params ? { fromObject: options.params } : {}
    );
    if (options.nav && options.nav.page && options.nav.page > 1) {
      params = params.set("page", options.nav.page.toString(10));
    }
    if (params.keys().length > 0) {
      url += "?" + params.toString();
    }
    this.current.alternate[lang] = url;
    this._head.setAlternate(lang, url);
    this._data$.next(this.current);
  }

  public setOgTitle(title: string): void {
    if (this._meta.getTag("property='og:title'")) {
      this._meta.updateTag(
        { property: "og:title", content: title },
        "property='og:title'"
      );
    } else {
      this._meta.addTag({ property: "og:title", content: title });
    }
    this.current.ogtitle = title;
    this._data$.next(this.current);
  }

  public setOgType(type: string): void {
    if (this._meta.getTag("property='og:type'")) {
      this._meta.updateTag(
        { property: "og:type", content: type },
        "property='og:type'"
      );
    } else {
      this._meta.addTag({ property: "og:type", content: type });
    }
    this.current.ogtype = type;
    this._data$.next(this.current);
  }

  public setOgDescription(description: string): void {
    if (this._meta.getTag("property='og:description'")) {
      this._meta.updateTag(
        { property: "og:description", content: description },
        "property='og:description'"
      );
    } else {
      this._meta.addTag({ property: "og:description", content: description });
    }
    this.current.ogdescription = description;
    this._data$.next(this.current);
  }

  public setOgUrl(url: string): void {
    if (this._meta.getTag("property='og:url'")) {
      this._meta.updateTag(
        { property: "og:url", content: url },
        "property='og:url'"
      );
    } else {
      this._meta.addTag({ property: "og:url", content: url });
    }
    this.current.ogurl = url;
    this._data$.next(this.current);
  }

  public setOgImage(image: string): void {
    if (this._meta.getTag("name='twitter:card'")) {
      this._meta.updateTag(
        {
          name: "twitter:card",
          content: image ? "summary_large_image" : "summary",
        },
        "name='twitter:card'"
      );
    } else {
      this._meta.addTag({
        name: "twitter:card",
        content: image ? "summary_large_image" : "summary",
      });
    }
    if (this._meta.getTag("property='og:image'")) {
      this._meta.updateTag(
        { property: "og:image", content: image },
        "property='og:image'"
      );
    } else {
      this._meta.addTag({ property: "og:image", content: image });
    }
    this.current.ogimage = image;
    this._data$.next(this.current);
  }
}
