import { Collection } from "./data-collection";
import { DataCache } from "./data-cache";
import { DataModel } from "./data-model";
import { combineLatest, firstValueFrom, Observable, ReplaySubject } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";

export interface FilterData {
  [index: string]: string | undefined | null;
}

export type SortData = string;

export interface IQuerysetOptions {
  name?: string;
  nocache?: boolean;
  route?: ActivatedRoute;
  router?: Router;
  hash?: string;
}

export interface IQueryMetaNav {
  count: number;
  next: number | null;
  page: number;
  pages: number;
  prev: number | null;
  size: number;
}

export interface IQueryMeta {
  nav: IQueryMetaNav;
  parameters: {
    page: string;
    pagesize: string;
  };
}

export class Queryset<T extends DataModel> {
  public filterData: FilterData = {};
  public sortData: SortData[] = [];
  public pageSize = 100;
  public page?: number;
  private _cache = new DataCache<T>();

  constructor(
    protected _coll: Collection<T>,
    public options: IQuerysetOptions = {}
  ) {}

  private _pristine = true;

  public get pristine(): boolean {
    return this._pristine;
  }

  private _loading = new ReplaySubject<boolean>(1);

  public get loading(): Observable<boolean> {
    return this._loading.asObservable();
  }

  private _meta = new ReplaySubject<IQueryMeta>(1);

  public get meta(): Observable<IQueryMeta> {
    return this._meta.asObservable();
  }

  private _results = new ReplaySubject<T[]>(1);

  public get results(): Observable<T[]> {
    return this._results.asObservable();
  }

  public get full(): Observable<[T[], boolean, IQueryMeta]> {
    return combineLatest([this.results, this.loading, this.meta]);
  }

  public filter(params: FilterData = {}, update: boolean = false): Queryset<T> {
    if (!update) {
      this.filterData = {};
    }
    for (const k of Object.keys(params)) {
      if (params[k] === undefined || params[k] === null) {
        delete this.filterData[k];
      } else {
        this.filterData[k] = params[k];
      }
    }
    return this;
  }

  public sort(...sorting: string[]): Queryset<T> {
    this.sortData = sorting;
    return this;
  }

  public paginateBy(pageSize: number = 20): Queryset<T> {
    this.pageSize = pageSize;
    return this;
  }

  public setPage(page: number = 1, fragment?: string): Queryset<T> {
    if (page !== this.page) {
      this.page = page;
      if (this.options.router) {
        const frag = fragment
          ? fragment
          : this.options.hash || this.options.name;
        this.options.router.navigate([], {
          queryParams: { page: page.toString() },
          fragment: frag,
          queryParamsHandling: "merge",
        });
        firstValueFrom(this.get(true));
      }
    }
    return this;
  }

  public getQueryParams(): FilterData {
    const f: FilterData = {};
    Object.assign(f, this.filterData);
    f.ordering = this.sortData.join(",");
    f.page_size = this.pageSize.toString();
    // Get page from route if not already set
    if (!this.page) {
      if (this.options.route) {
        if (this.options.route.snapshot.queryParams.page) {
          this.page = +this.options.route.snapshot.queryParams.page;
        } else {
          this.page = 1;
        }
      } else {
        this.page = 1;
      }
    }
    f.page = this.page.toString();
    return f;
  }

  public get(refresh: boolean = true): Observable<T[]> {
    if (!refresh && !this._pristine) {
      return this.results.pipe(first());
    }
    this._loading.next(true);
    return this._coll.list(this.getQueryParams() as any).pipe(
      switchMap((result) => {
        this._meta.next({ nav: result.nav, parameters: result.parameters });
        const out: T[] = [];
        for (const r of result.results) {
          const m = this._coll.fromJson(r);
          if (!this.options.nocache) {
            this._cache.set(m);
          }
          out.push(m);
        }
        this._pristine = false;
        this._loading.next(false);
        this._results.next(out);
        return this.results.pipe(first());
      })
    );
  }
}
