
<footer>
  <div class="zone0">
    <div class="container fs-2 text-center">
      <p class="text-uppercase text-white" i18n>Prenez RDV<br/>pour une démo live<br/> sur Zoom</p><a class="btn bg-black1 text-white btn-lg p-2 px-4" i18n-href href="https://calendly.com/cosmeticobs-simona-42/pro-abonnements-demo" title="RDV pour une démo zoom" i18n-title target="_blank"><i class="icon-calendar me-2"></i><span class="text-uppercase" i18n>Cliquez ici</span></a>
    </div>
  </div>
  <div class="zone1 d-none d-md-block">
    <div class="container">
      <div class="row">
        <ng-container *ngFor="let rg of rubrics; let last=last">
          <div class="col-sm-3">
            <ng-container *ngFor="let r of rg"><a class="main" *ngIf="r.level==0" [routerLink]="I18N_ROUTES.site_articles_rubric(r)" [title]="r.name">{{r.name}}</a><a class="sub" *ngIf="r.level==1" [routerLink]="I18N_ROUTES.site_articles_rubric(r)" [title]="r.name">{{r.name}}</a></ng-container>
            <ng-container *ngIf="last"><a class="main" title="Tous les articles par ordre chronologique" i18n-title [routerLink]="I18N_ROUTES.site_articles_chrono()" i18n>Tous les articles</a></ng-container>
          </div>
        </ng-container>
        <div class="col-sm-3">
          <p class="main" i18n>SUIVEZ-NOUS</p>
          <p class="social"><a href="http://www.twitter.com/CosmeticOBS" target="_blank" title="Suivez-nous sur twitter" i18n-title><i class="icon-twitter icon-2x"></i></a><a href="https://www.facebook.com/CosmeticOBS/" target="_blank" title="Suivez-nous sur facebook" i18n-title><i class="icon-facebook icon-2x"></i></a><a href="https://www.linkedin.com/company/observatoire-des-cosm%C3%A9tiques" target="_blank" title="Suivez-nous sur linkedin" i18n-title><i class="icon-linkedin icon-2x"></i></a></p><a class="link" [routerLink]="I18N_ROUTES.site_page(I18N_PAGES.quisommesnous)" i18n title="Qui sommes nous ?" i18n-title>QUI SOMMES NOUS ?</a><a class="link" [routerLink]="I18N_ROUTES.site_subscriptions" i18n title="Nos abonnements" i18n-title>S’ABONNER</a><a class="link" i18n-href href="https://calendly.com/cosmeticobs-simona-42/pro-abonnements-demo" target="_blank" i18n title="Prendre RDV pour une démo zoom" i18n-title>DEMONSTRATION</a><a class="link" href="https://cobs-front.s3.eu-west-2.amazonaws.com/assets/media-kit-cosmeticobs-202012-fr.pdf" i18n-href i18n target="_blank" title="Annoncer sur cosmeticobs" i18n-title>PUBLICITE</a><a class="link" [routerLink]="I18N_ROUTES.site_page(I18N_PAGES.mentionsLegales)" i18n title="Mentions légales" i18n-title>MENTIONS LEGALES</a><a class="link" [routerLink]="I18N_ROUTES.site_page(I18N_PAGES.contact)" i18n title="Contact" i18n-title>CONTACT</a><a class="link" [routerLink]="I18N_ROUTES.site_page(I18N_PAGES.faq)" i18n title="Foire aux question" i18n-title>FAQ</a>
        </div>
      </div>
    </div>
  </div>
  <div class="zone2">
    <div class="container">
      <div class="row">
        <div class="col-sm-3"><img class="logo" [src]="logoUrl" alt="Logo CosmeticOBS blanc" i18n-alt width="448" height="46"></div>
        <div class="col-sm-3">
          <p i18n><b>CosmeticOBS</b> : 1er portail indépendant dédié aux cosmétiques depuis 2008.</p>
          <p i18n>
            CosmeticOBS-L’Observatoire des Cosmétiques  est la 1ère source d’informations du secteur cosmétique.
            Réglementations européennes et internationales, tendances du marché, actualités des ingrédients, nouveautés produits, comptes-rendus de congrès et de salons professionnels : CosmeticOBS propose une veille cosmétique professionnelle actualisée en temps réel, au quotidien.
          </p>
        </div>
        <div class="col-sm-3">
          <p i18n>
            CosmeticOBS : News. Expertise. Monitoring.<br/>
            Information. Advice. Independance.
          </p>
          <p i18n>Copyright 2008-2022</p>
        </div>
        <div class="col-sm-3">
          <p>v{{version}}</p>
        </div>
      </div>
    </div>
  </div>
</footer>