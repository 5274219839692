import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformServer } from "@angular/common";
import { makeStateKey, TransferState } from "@angular/platform-browser";

export type AVAILABLE_STYLES =
  | "main"
  | "carousel"
  | "tables"
  | "forms"
  | "btngroup"
  | "modal"
  | "tooltip"
  | "popover"
  | "offcanvas";
export const STYLE_MODULES = {
  main: "styles-001.css",
  carousel: "bs-carousel-001.css",
  tables: "bs-tables-001.css",
  forms: "bs-forms-001.css",
  btngroup: "bs-button-group-001.css",
  modal: "bs-modal-001.css",
  tooltip: "bs-tooltip-001.css",
  popover: "bs-popover-001.css",
  offcanvas: "bs-offcanvas-001.css",
};

@Injectable({
  providedIn: "root",
})
export class StylesService {
  public loaded: string[] = [];

  constructor(
    @Inject(PLATFORM_ID) private _platform: any,
    private transferState: TransferState,
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public load(style: AVAILABLE_STYLES) {
    if (this.loaded.indexOf(style) !== -1) {
      return;
    }
    if (!STYLE_MODULES[style]) {
      console.error(`Style ${style} unknown`);
    }
    const key = makeStateKey<boolean>("__styles_service");
    if (isPlatformServer(this._platform)) {
      this.transferState.set<boolean>(key, true);
    }
    const head = this.document.getElementsByTagName("head")[0];
    let styleLink = this.document.getElementById(style) as HTMLLinkElement;
    if (styleLink) {
      styleLink.href = STYLE_MODULES[style];
      this.loaded.push(style);
    } else {
      styleLink = this.document.createElement("link") as HTMLLinkElement;
      styleLink.id = style;

      // if ((isPlatformBrowser(this._platform) && !this.transferState.hasKey(key))) {
      styleLink.rel = "stylesheet";
      styleLink.href = STYLE_MODULES[style];
      // } else {
      //   styleLink.media = "print";
      //   styleLink.rel = "stylesheet";
      //   styleLink.href = STYLE_MODULES[style];
      //   styleLink.onload = function() {
      //     if ((this as any).media !== "all") {
      //       (this as any).media = "all";
      //     }
      //   };
      //   console.log(`Loaded ${style} style (non blocking)`);
      // }
      head.appendChild(styleLink);
      this.loaded.push(style);
    }
  }
}
