import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { I18N_ROUTES } from "../../../app-routes";
import { SalesServiceService } from "../../../billing/services/sales-service.service";
import { Observable, of } from "rxjs";
import { RouteParserService } from "../../../common/routes/route-parser.service";
import { first, map, switchMap } from "rxjs/operators";
import { RedirectorService } from "./redirector.service";

@Component({
  selector: "cobs-redirector",
  templateUrl: "./redirector.component.pug",
  styleUrls: ["./redirector.component.sass"],
  standalone: true,
})
export class RedirectorComponent implements OnInit {
  constructor(
    public route: ActivatedRoute,
    private _services: SalesServiceService,
    private _rparser: RouteParserService,
    private _redirector: RedirectorService
  ) {}

  ngOnInit(): void {
    if (!this.route.snapshot.data.redirect) {
      console.error("No redirection instructions");
      return;
    }
    let route: Observable<string[]> | null = null;
    // TODO: check usage and remove this after 2022-01-01
    switch (this.route.snapshot.data.redirect) {
      case "ebookDetail":
        route = of(
          I18N_ROUTES.site_eshop_ebook_details({
            i18nSlugId: () => this.route.snapshot.params.ebook,
          })
        );
        break;
      case "subscriptionDetail":
        route = this._rparser.slugId(this.route, "subscription").pipe(
          first(),
          switchMap((id) => this._services.fetch(id as number)),
          map((svc) => I18N_ROUTES.site_subscriptions_details(svc.code))
        );
        break;
      case "ebookList":
        route = of(I18N_ROUTES.site_eshop_home);
        break;
      case "subscriptionList":
        route = of(I18N_ROUTES.site_subscriptions);
        break;
      case "ingredientList":
        route = of(I18N_ROUTES.site_ingredients);
        break;
      case "ingredientDetail":
        route = of(
          I18N_ROUTES.site_ingredients_detail({
            slugId: this.route.snapshot.params.ingredient,
          })
        );
        break;
    }
    if (!route) {
      return;
    }
    route.subscribe((rpath) => {
      this._redirector.redirect(rpath, true);
    });
  }
}
