import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { isPlatformServer } from "@angular/common";
import { first, map } from "rxjs/operators";
import { Observable, of } from "rxjs";

export class RouteParserTestingService {
  public id = 1;

  public slugId(route: any, name: any): Observable<number> {
    return of(this.id);
  }
}

@Injectable({
  providedIn: "root",
})
export class RouteParserService {
  constructor(@Inject(PLATFORM_ID) private _platform: any) {}

  public static slugId(
    route: ActivatedRouteSnapshot,
    name: string
  ): number | undefined {
    if (route.params[name] === undefined) {
      return undefined;
    }
    const comps = route.params[name].split("-");
    return parseInt(comps[comps.length - 1], 10);
  }

  public slugId(
    route: ActivatedRoute,
    name: string
  ): Observable<number | undefined> {
    if (isPlatformServer(this._platform)) {
      return route.params.pipe(
        first(),
        map((params) => {
          if (params[name] === undefined) {
            return undefined;
          }
          const comps = params[name].split("-");
          return parseInt(comps[comps.length - 1], 10);
        })
      );
    } else {
      return route.params.pipe(
        map((params) => {
          if (params[name] === undefined) {
            return undefined;
          }
          const comps = params[name].split("-");
          return parseInt(comps[comps.length - 1], 10);
        })
      );
    }
  }
}
