import { DataModel } from "../../common/data/data-model";
import { Image } from "../../common/display/image";
// noinspection TypeScriptPreferShortImport
import { primaryField } from "../../common/data/fields/primaryfield";
// noinspection TypeScriptPreferShortImport
import { charField } from "../../common/data/fields/stringfields";
// noinspection TypeScriptPreferShortImport
import { dateField } from "../../common/data/fields/datetime";
// noinspection TypeScriptPreferShortImport
import { imageField } from "../../common/data/fields/image";
// noinspection TypeScriptPreferShortImport
import { booleanField } from "../../common/data/fields/boolean";
// noinspection TypeScriptPreferShortImport
import {
  detailsField,
  foreignKeyField,
  manyToManyField,
} from "../../common/data/fields/relations";
import { Content } from "../../common/content/services/content";
import { RubricBase } from "./rubric.base";
import { integerField } from "../../common/data/fields";

export class ArticleBase extends DataModel {
  static readonly _type: string = "ArticleBase";
  @primaryField()
  public id!: number;
  @charField()
  public title!: string;
  @charField()
  public slug!: string;
  @dateField()
  public datedisplay!: Date;
  @imageField()
  public icon_details!: Image;
  @booleanField()
  public dispaudio!: boolean;
  @booleanField()
  public dispsummary!: boolean;
  @booleanField()
  public dispvideo!: boolean;
  @charField()
  public authors!: string;
  @charField()
  public display!: string;
  @booleanField()
  public public!: boolean;
  @booleanField()
  public registered!: boolean;
  @detailsField()
  public i18n_slugs!: { [index: string]: string };
  @detailsField()
  public chapo_details!: Content;
  @foreignKeyField({ related: "Content" })
  public summary!: number;
  @manyToManyField({ related: "VideoContent" })
  public videocontent!: number[];
  @manyToManyField({ related: "AudioContent" })
  public audiocontent!: number[];
  @integerField()
  public read_time!: number;
  @charField()
  public meta_title!: string;
  @charField()
  public meta_description!: string;
  @charField()
  public meta_keywords!: string;
  @detailsField({ model: RubricBase })
  public rubric_details?: RubricBase;
  @detailsField({ many: true })
  public allowed_contracts!: string[];

  private _badgesShort!: string[];

  public get badgesShort(): string[] {
    if (this._badgesShort === undefined) {
      if (this.public) {
        this._badgesShort = [];
      } else if (this.registered) {
        this._badgesShort = ["R"];
      } else if (this.allowed_contracts) {
        if (this.allowed_contracts.indexOf("spro") >= 0) {
          this._badgesShort = ["P"];
        } else if (this.allowed_contracts.indexOf("spre") >= 0) {
          this._badgesShort = ["P"];
        }
      } else {
        this._badgesShort = [];
      }
    }
    return this._badgesShort;
  }

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }

  public i18nSlugId(lang: string): string {
    if (this.i18n_slugs && this.i18n_slugs[lang]) {
      return `${this.i18n_slugs[lang]}-${this.id}`;
    }
    return `${this.slug}-${this.id}`;
  }
}
