import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {firstValueFrom, Observable, of, ReplaySubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, first, map, switchMap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {CookiesService} from "../../common/cookies/cookies.service";
import {isPlatformBrowser} from "@angular/common";
import {DeviceIdService} from "../../common/stats/device-id.service";
import {SessionService} from "../../common/stats/session.service";

/**
 * Site user class.
 */
export class Auth {
  public id!: number;
  public email!: string;
  public lastname!: string;
  public firstname!: string;
  public isAnonymous = true;
  public isActive = false;
  public isStaff = false;
  public isSuperuser = false;
  public profile!: string[];
  public token!: string;
  public expire!: Date;
  public site!: number;
  public type!: string;

  public get isPro(): boolean {
    if (this.isAnonymous) {
      return false;
    }
    return (
      this.profile.indexOf("spro") !== -1 ||
      this.profile.indexOf("spre") !== -1 ||
      this.profile.indexOf("ssta") !== -1 ||
      this.profile.indexOf("stpe") !== -1
    );
  }

  public loadFromApi(data: any): void {
    if (!data || !data.user || !data.token) {
      console.error("Nothing to load : ", data);
      return;
    }
    this.id = data.user.id;
    this.email = data.user.email;
    this.lastname = data.user.lastname;
    this.firstname = data.user.firstname;
    this.isAnonymous = false;
    this.isActive = data.user.is_active || data.user.status === "OKW";
    this.isStaff = data.user.is_staff;
    this.isSuperuser = data.user.is_superuser;
    this.profile = data.user.profile;
    this.token = data.token.token;
    this.expire = data.token.expire;
    this.site = data.token.site;
    this.type = data.token.type;
  }

  public stringify(): string {
    return JSON.stringify(this);
  }

  /**
   * Return a boolean if user have access to specified resource with restrictions.
   *
   * @param restrictions Allowed contracts ?
   * @param pub Public ?
   * @param registered Registered ?
   */
  public haveAccess(
    restrictions: string[],
    pub?: boolean,
    registered?: boolean
  ): boolean {
    if (
      (!restrictions || restrictions.length === 0) &&
      pub !== false &&
      registered !== true
    ) {
      return true;
    }
    if (restrictions.indexOf("public") !== -1 || pub === true) {
      return true;
    }
    if (
      (restrictions.indexOf("registered") !== -1 || registered === true) &&
      !this.isAnonymous
    ) {
      return true;
    }
    if (this.profile && this.profile.length) {
      for (const p of this.profile) {
        if (restrictions.indexOf(p) !== -1) {
          return true;
        }
      }
    }
    return false;
  }
}

/**
 * Login status messages and details.
 */
export interface LoginStatus {
  success: boolean;
  status: number;
  message: string;
  devices?: { token: string; description: string }[];
}

/**
 * Authentication service.
 *
 * Provides login, logout and all functions for user authentication.
 */
@Injectable({
  providedIn: "root",
})
export class AuthService {
  /**
   * Current auth subject.
   */
  private _user$!: ReplaySubject<Auth>;

  /**
   * Auth service constructor.
   * @param _deviceid DeviceId service
   * @param _cookies Cookies service
   * @param _session Session service
   * @param _http Http client
   * @param _platform Platform id
   */
  constructor(
    private _deviceid: DeviceIdService,
    private _cookies: CookiesService,
    private _session: SessionService,
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private _platform: any
  ) {}

  /**
   * Username & password login.
   *
   * Calls /api/auth to check username & password. If OK, sets current
   * user & set authentication cookie.
   * @param username user name or email
   * @param password user password
   * @param disconnect token to disconnect (optional)
   * @return auth status (success / failure + message)
   */
  public passwordLogin(
    username: string,
    password: string,
    disconnect?: string
  ): Observable<LoginStatus> {
    // POST email/pw, store data
    this.init();
    return this.apiLogin({
      username,
      password,
      site: "cosmeticobs.com",
      device: this._deviceid.current,
      disconnect,
    });
  }

  /**
   * Token login.
   *
   * Call /api/auth with token parameter. If OK, sets current
   * user & set authentication cookie.
   *
   * @param token user token
   * @return auth status (success / failure + message
   */
  public tokenLogin(token: string): Observable<LoginStatus> {
    this.init();
    return this.apiLogin(
      {
        token,
        site: "cosmeticobs.com",
        device: this._deviceid.current,
      },
      true
    );
  }

  /**
   * Logout user.
   * This removes token cookie and renews session.
   */
  public logout(): Observable<LoginStatus> {
    this.init();
    this._cookies.remove("token");
    this._session.renew();
    this._user$.next(new Auth());
    return of({ success: true, status: 200, message: "OK" });
  }

  /**
   * Autologin using cookie.
   * Use cookie data to log user in. If refresh is true, checks
   * that user access & token are still valid.
   * @return login status message (success / failure)
   */
  public autologin(): Observable<LoginStatus> {
    this.init();
    if (this._cookies.get("token")) {
      const token = this._cookies.get("token");
      return this.tokenLogin(token);
    } else {
      this._user$.next(new Auth());
      return of({ success: false, status: 403, message: "No auth found" });
    }
  }

  /**
   * Refresh authentication
   */
  public async refresh(): Promise<void> {
    await firstValueFrom(this.autologin());
  }

  /**
   * Get current user as observable (one shot only : using first())
   * Tries autologin if not tried before.
   */
  public current$(): Observable<Auth> {
    let out;
    if (!this.init()) {
      this.autologin().subscribe(() => {});
      out = this._user$.asObservable();
    } else {
      out = this._user$.asObservable();
    }
    if (!isPlatformBrowser(this._platform)) {
      out = out.pipe(first());
    }
    return out;
  }

  /**
   * Process authentication response from API.
   * @param response AuthResponse from api
   */
  public processResponse(response: any) {
    const user = new Auth();
    user.loadFromApi(response);
    const exp = new Date();
    exp.setDate(exp.getDate() + 3650);
    if (this._cookies.get("token") !== user.token) {
      this._cookies.put("token", user.token, {
        path: "/",
        expires: exp,
        secure: environment.production,
        httpOnly: false,
        sameSite: "Strict",
      });
    }
    this._user$.next(user);
    return user;
  }

  /**
   * Initialization of user subject.
   * Returns true if already created; false if not created.
   * @return true if already created, false if not created
   */
  private init(): boolean {
    if (!this._user$) {
      this._user$ = new ReplaySubject<Auth>(1);
      return false;
    }
    return true;
  }

  /**
   * API Login.
   *
   * Calls /api/auth to check username & password. If OK, sets current
   * user & set authentication cookie.
   * @param body api payload
   * @param get use get method to retrieve auth data ?
   * @return auth status (success / failure + message)
   */
  private apiLogin(body: any, get: boolean = false): Observable<LoginStatus> {
    // POST email/pw, store data
    this.init();
    let query;
    if (get) {
      query = this._http.get(environment.API_URL + "/api/auth", {
        params: body,
      });
    } else {
      query = this._http.post(environment.API_URL + "/api/auth", body);
    }
    return query.pipe(
      map((response) => this.processResponse(response)),
      switchMap(() => {
        return of({ success: true, status: 200, message: "OK" });
      }),
      catchError((err) => {
        return this.processError(err);
      })
    );
  }

  private processError(err: any) {
    const disconnect = () => {
      const user = new Auth();
      this._user$.next(user);
      this._cookies.remove("token");
      this._session.renew();
      if (err.error && err.error.error) {
        return of({
          success: false,
          status: err.status,
          message: err.error.error,
          devices: err.error.devices,
        });
      }
      return of({
        success: false,
        status: err.status,
        message: "Unknown error",
      });
    };

    console.error("Authentication error : ", err);
    if (err && err.status !== undefined) {
      if (err.status === 0) {
        return of({
          success: false,
          status: err.status,
          message: "Network error, please retry later",
        });
      }
      if (err.status === 429) {
        return of({
          success: false,
          status: err.status,
          message: "Too many requests, slow down please",
        });
      }
      if (err.status >= 400 && err.status < 500) {
        return disconnect();
      }
    }
    return of({ success: false, status: err.status, message: "Unknown error" });
  }
}
