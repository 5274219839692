import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Auth, AuthService } from "../../../access/services/auth.service";
import { I18N_ROUTES } from "../../../app-routes";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DeployUrlService } from "../../../common/display/deploy-url.service";
import { NgbOffcanvas, NgbOffcanvasRef } from "@ng-bootstrap/ng-bootstrap";
import { StylesService } from "../../../common/styles/styles.service";
import { HeaderMenuComponent } from "./header-menu/header-menu.component";
import { HeaderSubscribeComponent } from "./header-subscribe/header-subscribe.component";
import { HeaderUserinfosComponent } from "./header-userinfos/header-userinfos.component";
import { LazyComponentComponent } from "../../../common/display/lazyness/lazy-component/lazy-component.component";
import { RouterLink } from "@angular/router";
import { HeaderSocialComponent } from "./header-social/header-social.component";
import { HeaderLanguageComponent } from "./header-language/header-language.component";
import { HeaderCreditsComponent } from "./header-credits/header-credits.component";
import { HeaderMessagesComponent } from "./header-messages/header-messages.component";
import { CartIconComponent } from "../../../billing/components/cart-icon/cart-icon.component";

@Component({
  selector: "cobs-header",
  templateUrl: "./header.component.pug",
  styleUrls: ["./header.component.sass"],
  standalone: true,
  imports: [
    HeaderLanguageComponent,
    HeaderSocialComponent,
    RouterLink,
    LazyComponentComponent,
    HeaderUserinfosComponent,
    HeaderSubscribeComponent,
    HeaderMenuComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isMenuCollapsed = true;
  public auth: Auth = new Auth();
  public logoUrl!: string;
  public I18N_ROUTES = I18N_ROUTES;
  private _subscriptions = new Subject<void>();
  private _mnuref?: NgbOffcanvasRef;
  @ViewChild("sidemenu", { static: true }) public ofct!: TemplateRef<any>;

  constructor(
    private _auth: AuthService,
    private _ofcs: NgbOffcanvas,
    private _styles: StylesService,
    private _zone: NgZone,
    public deploy: DeployUrlService
  ) {}

  public ngOnDestroy(): void {
    this._subscriptions.next();
    this._subscriptions.complete();
  }

  public ngOnInit(): void {
    this._styles.load("offcanvas");
    this.logoUrl = this.deploy.url("/assets/images/logos/logo_odc.png");
    this._auth
      .current$()
      .pipe(takeUntil(this._subscriptions))
      .subscribe((auth) => {
        this.auth = auth;
      });
  }

  public toggleMenu(close?: boolean) {
    if (!this._mnuref && !close) {
      this._mnuref = this._ofcs.open(this.ofct);
    } else {
      if (this._mnuref) {
        this._mnuref.close();
        delete this._mnuref;
      }
    }
  }
}
