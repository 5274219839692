import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SalesServiceService } from "../../../billing/services/sales-service.service";
import { RouteParserService } from "../../../common/routes/route-parser.service";
import { ServerResponseService } from "../../../common/errors/server-response.service";
import { isPlatformServer } from "@angular/common";
import { CURRENT_LOCALE_ID } from "../../../i18n_setup";

@Injectable({
  providedIn: "root",
})
export class RedirectorService {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    @Inject(PLATFORM_ID) public platform: any,
    private _services: SalesServiceService,
    private _rparser: RouteParserService,
    private _sresponse: ServerResponseService
  ) {}

  public redirect(rpath: string[], permanent: boolean = false) {
    if (isPlatformServer(this.platform)) {
      if (permanent) {
        this._sresponse
          .setStatus(301, "Permanent redirect")
          .setHeader(
            "Location",
            "/" + CURRENT_LOCALE_ID + rpath.join("/").substring(1)
          )
          .setCacheNone();
      } else {
        this._sresponse
          .setStatus(302, "Temporary redirect")
          .setHeader(
            "Location",
            "/" + CURRENT_LOCALE_ID + rpath.join("/").substring(1)
          )
          .setCacheNone();
      }
    } else {
      this.router.navigate(rpath);
    }
  }
}
