import { DataModel } from "../../common/data/data-model";
import {
  booleanField,
  charField,
  decimalField,
  detailsField,
  foreignKeyField,
  imageField,
  integerField,
  primaryField,
  textField,
} from "../../common/data/fields";
import { contentField } from "../../common/data/fields/content";
import { Content } from "../../common/content/services/content";
import { Image } from "../../common/display/image";

export class SalesService extends DataModel {
  static readonly _type: string = "SalesService";

  @primaryField()
  public id!: number;

  @foreignKeyField({ related: "SalesProductType" })
  public type!: number;

  @foreignKeyField({ related: "SalesCategory" })
  public categ!: number;

  @charField()
  public slug!: string;

  @charField()
  public code!: string;

  @charField()
  public title!: string;

  @textField()
  public description!: string;

  @foreignKeyField({ related: "AccessSubscriptionType" })
  public subscriptiontype!: number;

  @charField({
    maxLength: 10,
    choices: ["day", "week", "month", "year"],
  })
  public interval!: string;

  @imageField()
  public icon_details!: Image;

  @integerField()
  public interval_count!: number;

  @integerField()
  public expiration!: number;

  @integerField()
  public order!: number;

  @booleanField()
  public onetime!: boolean;

  @booleanField()
  public recurrent!: boolean;

  @decimalField()
  public price!: number;

  @foreignKeyField({ related: "Content" })
  public chapo!: number;

  @contentField({ chapo: true })
  public chapo_details!: Content;

  @foreignKeyField({ related: "Content" })
  public content!: number;

  @contentField()
  public content_details!: Content;

  @charField()
  public meta_description!: string;

  @charField()
  public meta_keywords!: string;

  @detailsField()
  public i18n_slugs!: { [index: string]: string };

  @detailsField()
  public categs_details!: {
    id: number;
    order: number;
    service: number;
    category: number;
  }[];

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }

  public categOrder(categId: number): number {
    if (categId === -1) {
      return this.order;
    }
    if (!this.categs_details) {
      return this.order;
    }
    for (const cd of this.categs_details) {
      if (cd.category === categId) {
        return cd.order;
      }
    }
    return this.order;
  }

  public i18nSlugId(lang: string): string {
    if (this.i18n_slugs && this.i18n_slugs[lang]) {
      return `${this.i18n_slugs[lang]}-${this.id}`;
    }
    return `${this.slug}-${this.id}`;
  }
}
