import { ApplicationConfig, mergeApplicationConfig } from "@angular/core";
import { provideServiceWorker } from "@angular/service-worker";
// import * as Sentry from "@sentry/browser";
import { CURRENT_LOCALE_ID } from "./i18n_setup";
import { appCommonConfig } from "./app.config.common";
import { environment } from "../environments/environment";
import { BrowserCookiesService } from "./common/cookies/browser";
import {
  COOKIES_OPTIONS,
  CookiesOptionsService,
} from "./common/cookies/cookies-options.service";
import { CookiesService } from "./common/cookies/cookies.service";
import { provideClientHydration } from "@angular/platform-browser";

// Sentry.init({
//   enabled: environment.production,
//   dsn: "https://873698840223491da513c43f392c7c84@sentry.solidev.net/2",
//   environment: environment.MODE,
//   release: environment.VERSION + "@" + environment.RELEASE,
//   integrations: [new ExtraErrorData()],
//   autoSessionTracking: false
//   // beforeSend(event, hint) {
//   //   const isNonErrorException =
//   //     event.exception.values[0].value.startsWith("Non-Error exception captured") ||
//   //     hint.originalException["message"].startsWith("Non-Error exception captured");
//   //   if (isNonErrorException) {
//   //     return null;
//   //   }
//   //   return event;
//   // }
// });
//
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {
//   }
//
//   public extractError(error) {
//     // Try to unwrap zone.js error.
//     // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
//     if (error && error.ngOriginalError) {
//       error = error.ngOriginalError;
//     }
//
//     // We can handle messages and Error objects directly.
//     if (typeof error === "string" || error instanceof Error) {
//       return error;
//     }
//
//     // If it's http module error, extract as much information from it as we can.
//     if (error instanceof HttpErrorResponse) {
//       // The `error` property of http exception can be either an `Error` object, which we can use directly...
//       if (error.error instanceof Error) {
//         return error.error;
//       }
//
//       // ... or an`ErrorEvent`, which can provide us with the message but no stack...
//       if (error.error instanceof ErrorEvent) {
//         return error.error.message;
//       }
//
//       // ...or the request body itself, which we can use as a message instead.
//       if (typeof error.error === "string") {
//         return `Server returned code ${error.status} with body "${error.error}"`;
//       }
//
//       // If we don't have any detailed information, fallback to the request message itself.
//       return error.message;
//     }
//
//     const newErr = new Error(error.message || "unexpected");
//     for (const [key, value] of Object.entries(error)) {
//       newErr[key] = value;
//     }
//     return newErr;
//   }
//
//   handleError(error) {
//     const extractedError = this.extractError(error) || "Handled unknown error";
//
//     // Capture handled exception and send it to Sentry.
//     Sentry.captureException(extractedError);
//     if (!environment.production) {
//       console.error(error);
//     }
//   }
// }

const appBrowserConfig: ApplicationConfig = {
  providers: [
    { provide: COOKIES_OPTIONS, useValue: {} },
    CookiesOptionsService,
    { provide: CookiesService, useClass: BrowserCookiesService },
    provideServiceWorker(`/${CURRENT_LOCALE_ID}/service-worker.js`, {
      enabled: environment.MODE !== "devel",
      scope: `/${CURRENT_LOCALE_ID}/`,
      registrationStrategy: "registerWhenStable:1000",
    }),
  ],
};

export const config = mergeApplicationConfig(appCommonConfig, appBrowserConfig);
