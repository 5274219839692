import { Injectable } from "@angular/core";
import {
  charField,
  detailsField,
  foreignKeyField,
  imageField,
  integerField,
  primaryField,
  textField,
} from "../../common/data/fields";
import { DataModel } from "../../common/data/data-model";
import { Image } from "../../common/display/image";
import { Content } from "../../common/content/services/content";

@Injectable({
  providedIn: "root",
})
export class RubricBase extends DataModel {
  static readonly _type: string = "RubricBase";

  @primaryField()
  public id!: number;

  @foreignKeyField({ related: "Rubric" })
  public parent!: number;

  @imageField()
  public icon_details!: Image;

  @charField()
  public meta_keywords!: string;

  @textField()
  public meta_description!: string;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @detailsField()
  public chapo_details!: Content;

  @foreignKeyField({ related: "Content" })
  public chapo!: number;

  @foreignKeyField({ related: "Content" })
  public content!: number;

  @charField()
  public display!: "ART" | "FOL" | "AFO";

  @integerField()
  public order!: number;

  @integerField()
  public level!: number;

  @detailsField()
  public i18n_slugs!: { [index: string]: string };

  @charField({ choices: ["OK", "OKN", "NOK"] })
  public status!: "OK" | "NOK" | "OKN";

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }

  public i18nSlugId(lang: string): string {
    if (this.i18n_slugs && this.i18n_slugs[lang]) {
      return `${this.i18n_slugs[lang]}-${this.id}`;
    }
    return `${this.slug}-${this.id}`;
  }
}
