import { DataModel } from "../../common/data/data-model";
import { charField, primaryField } from "../../common/data/fields";

export class Line extends DataModel {
  static readonly _type: string = "Line";

  @primaryField()
  public id!: number;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @charField()
  public meta_title!: string;

  @charField()
  public meta_keywords!: string;

  @charField()
  public meta_description!: string;

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }
}
