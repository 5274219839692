import { DataModel } from "../../common/data/data-model";
import {
  charField,
  datetimeField,
  detailsField,
  imageField,
  primaryField,
} from "../../common/data/fields";
import { Image } from "../../common/display/image";

interface IRoleDetails {
  type: string;
  name: string;
  description: string;
}

export class IngredientBase extends DataModel {
  static readonly _type: string = "IngredientBase";

  @primaryField()
  public id!: number;

  @charField()
  public name!: string;

  @charField()
  public slug!: string;

  @charField()
  public inciname!: string;

  @detailsField()
  public tknames!: string[];

  @detailsField()
  public origin!: string[];

  @detailsField()
  public roles!: string[];

  @charField()
  public regulation!: string;

  @charField()
  public cas!: string;

  @charField()
  public einecs!: string;

  @detailsField()
  public i18n_slugs!: { [index: string]: string };

  @imageField()
  public icon_details!: Image;

  @charField()
  public type!: "ANIM" | "VEGE" | "MINE" | "SYNT";

  @datetimeField()
  public created!: Date;

  @datetimeField()
  public updated!: Date;

  @detailsField()
  public geoorigins_details!: { geoorigin: string; comment: string }[];

  @detailsField()
  public providers_details!: {
    provider: string;
    pwebsite: string;
    comment: string;
    website: string;
    name: string;
  }[];

  @detailsField()
  public safety_details!: {
    code: string;
    name: string;
    type: string;
    comment: string;
  }[];

  @detailsField()
  public roles_details!: {
    COS: IRoleDetails[];
    PRI: IRoleDetails[];
    NOF: IRoleDetails[];
  };

  @detailsField()
  public flagcontent!: number[];

  public get slugId(): string {
    return `${this.slug}-${this.id}`;
  }

  public i18nSlugId(lang: string): string {
    if (this.i18n_slugs && this.i18n_slugs[lang]) {
      return `${this.i18n_slugs[lang]}-${this.id}`;
    }
    return `${this.slug}-${this.id}`;
  }
}
