import { DataModel } from "./data-model";

export class DataCacheItem<T extends DataModel> {
  item!: T;
  expire!: number;
}

export class DataCache<T extends DataModel> {
  private _data: { [index: number]: DataCacheItem<T> } = {};
  private _ttl: number = 5 * 60;

  constructor(ttl?: number) {
    if (ttl) {
      this._ttl = ttl;
    }
  }

  public get(id: number): T | undefined {
    if (this._data[id] !== undefined) {
      this._data[id].expire = new Date().getTime() + this._ttl * 1000;
      return this._data[id].item;
    }
    return undefined;
  }

  public set(data: T) {
    // if (Math.random() > 0.9) {
    //   this.expire();
    // }
    // this._data[data.id] = {
    //   item: data,
    //   expire: new Date().getTime() + this._ttl * 1000
    // };
  }

  public clear(): void {
    this._data = {};
  }

  public expire(): void {
    const data: { [index: number]: DataCacheItem<T> } = {};
    const now = new Date().getTime();
    for (const i in this._data) {
      if (this._data[i].expire >= now) {
        data[i] = this._data[i];
      }
    }
    this._data = data;
  }
}
