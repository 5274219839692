import { ArticleBase } from "../../../articles/services/article.base";
import { RubricBase } from "../../../articles/services/rubric.base";
import { IngredientBase } from "../../../ingredients/services/ingredient.base";
import { ProductBase } from "../../../products/services/product.base";
import { environment } from "../../../../environments/environment";
import { I18N_ROUTES } from "../../../app-routes";
import { BrandBase } from "../../../products/services/brand.base";
import { SalesProduct } from "../../../billing/services/sales-product";
import { CURRENT_LOCALE_ID } from "../../../i18n_setup";

export class Content {
  id!: number;
  body?: ContentDetails;
  restricted!: boolean;

  constructor(data?: any) {
    this.fromJson(data);
  }

  public fromJson(data?: any): void {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.restricted = data.restricted;
    if (data.body) {
      this.body = new ContentDetails(data.body);
    }
  }

  public getSummary(length: number = 200) {
    let out = "";
    for (const bi of this.body?.parts || []) {
      out += bi.getText();
      if (out.length > length) {
        break;
      }
    }
    if (out.length > length) {
      out = out.substring(0, length) + "...";
    }
    return out;
  }
}

export class ContentDetails {
  links!: ContentLink[];
  parts!: ContentPart[];

  constructor(data: any) {
    this.links = [];
    this.parts = [];

    if (data.links) {
      for (const l of data.links) {
        this.links.push(new ContentLink(l));
      }
    }
    if (data.parts) {
      for (const l of data.parts) {
        this.parts.push(new ContentPart(l));
      }
    }
  }
}

export class ContentPart {
  type: "text" | "gallery" | "ad";
  data: string;
  items: ContentLink[];

  constructor(data: any) {
    this.type = data.type;
    this.data = data.data;
    this.items = [];
    if (data.items) {
      for (const l of data.items) {
        this.items.push(new ContentLink(l));
      }
    }
  }

  public getText(): string {
    return this.data.replace(/<[^>]*>/g, "");
  }
}

export class ContentMedia {
  public lang!: string;
  public type!: string;
  public legend!: string;
  public credits!: string;
  public media!: string;
  public order!: number;
  public gallery!: string;
  public icon!: boolean;
  public status!: string;

  constructor(data?: any) {
    if (data) {
      this.fromJson(data);
    }
  }

  public fromJson(data: ContentMedia) {
    this.lang = data.lang;
    this.type = data.type;
    this.legend = data.legend;
    this.credits = data.credits;
    this.media = data.media;
    this.order = data.order;
    this.gallery = data.gallery;
    this.icon = data.icon;
    this.status = data.status;
  }
}

export class ContentLink {
  display: "GALLERY" = "GALLERY";
  display_name!: string;
  id!: number;
  order!: number;
  textlink!: string;
  title!: string;
  type!: string;
  url!: string;
  article!: ArticleBase;
  ingredient!: IngredientBase;
  product!: ProductBase;
  brand!: BrandBase;
  sproduct!: SalesProduct;

  constructor(data?: ContentLink) {
    if (data) {
      this.fromJson(data);
    }
  }

  public fromJson(data: ContentLink) {
    this.display = data.display;
    this.display_name = data.display_name;
    this.id = data.id;
    this.order = data.order;
    this.title = data.title;
    this.textlink = data.textlink;
    this.type = data.type;
    this.url = data.url;

    if (data.article) {
      this.article = new ArticleBase();
      this.article.fromJson(data.article);
      if (data.article.chapo_details) {
        this.article.chapo_details = new Content(data.article.chapo_details);
      }
      if (data.article.rubric_details) {
        this.article.rubric_details = new RubricBase();
        this.article.rubric_details.fromJson(data.article.rubric_details);
      }
    }
    if (data.ingredient) {
      this.ingredient = new IngredientBase();
      this.ingredient.fromJson(data.ingredient);
    }
    if (data.product) {
      this.product = new ProductBase();
      this.product.fromJson(data.product);
    }
  }

  public getUrl(): string {
    if (this.url) {
      return this.url;
    }
    if (this.type === "EXTLINK") {
      return this.textlink;
    }
    if (this.type === "EXTDOC") {
      return this.textlink;
    }
    if (this.type === "COBSART" && this.article) {
      return (
        environment.MAIN_URL +
        "/" +
        CURRENT_LOCALE_ID +
        I18N_ROUTES.site_articles_article(
          this.article.rubric_details,
          this.article
        )
          .join("/")
          .replace("//", "/")
      );
    }
    if (this.type === "COBSING" && this.ingredient) {
      return (
        environment.MAIN_URL +
        "/" +
        CURRENT_LOCALE_ID +
        I18N_ROUTES.site_ingredients_detail(this.ingredient)
          .join("/")
          .replace("//", "/")
      );
    }
    if (this.type === "COBSPRD" && this.product) {
      return (
        environment.MAIN_URL +
        "/" +
        CURRENT_LOCALE_ID +
        I18N_ROUTES.site_product_details(
          this.product.brand_details,
          this.product.line_details,
          this.product
        )
          .join("/")
          .replace("//", "/")
      );
    }
    if (this.type === "COBSBRD" && this.brand) {
      return (
        environment.MAIN_URL +
        "/" +
        CURRENT_LOCALE_ID +
        I18N_ROUTES.site_brands_detail(this.brand).join("/").replace("//", "/")
      );
    }
    if (this.type === "COBSSTR" && this.sproduct) {
      return (
        environment.MAIN_URL +
        "/" +
        CURRENT_LOCALE_ID +
        I18N_ROUTES.site_eshop_ebook_details(this.sproduct)
          .join("/")
          .replace("//", "/")
      );
    }
    return "";
  }

  public getRoute(): string[] | null {
    if (this.type === "COBSART" && this.article) {
      return I18N_ROUTES.site_articles_article(
        this.article.rubric_details,
        this.article
      );
    }
    if (this.type === "COBSING" && this.ingredient) {
      return I18N_ROUTES.site_ingredients_detail(this.ingredient);
    }
    if (this.type === "COBSPRD" && this.product) {
      return I18N_ROUTES.site_product_details(
        this.product.brand_details,
        this.product.line_details,
        this.product
      );
    }
    return null;
  }
}
